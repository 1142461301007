import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useLocation } from "react-router-dom";
import { UrlCollection } from "../../common/url-collection";
import LinkAdministratorItems from "./child-components/link-adminstrator-items.view";

//--- Material Icon
import EmailIcon from "@material-ui/icons/Email";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import HomeIcon from "@material-ui/icons/Home";
import PostAddIcon from "@material-ui/icons/PostAdd";
import PeopleIcon from "@material-ui/icons/People";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SettingsIcon from "@material-ui/icons/Settings";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import CallToActionIcon from "@material-ui/icons/CallToAction";
import HistoryIcon from "@material-ui/icons/History";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import ViewAgendaIcon from "@material-ui/icons/ViewAgenda";
import TableChartIcon from '@material-ui/icons/TableChart';
import { makeStyles, } from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import ListIcon from '@material-ui/icons/List';
import MapIcon from '@material-ui/icons/Map';

//--- Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import * as clientSettingAction from "../../redux/store/client_setting/client_setting.store";

//--- Style
import "./sidebar.scss";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#00923F",
    },
}));

function Sidebar(props) {
    const {
        settings,
        getSettings
    } = props;

    useEffect(() => {
        getSettings();
    }, []);

    useEffect(() => {
        setClientSetting(settings);
    }, [settings]);

    const [clientSetting, setClientSetting] = useState();

    const [isToggleSidebar, setIsToggleSidebar] = useState(false);
    const currentLocation = useLocation();
    const onToggleSidebar = () => {
        setIsToggleSidebar(!isToggleSidebar);
    };
    const currentIsConstructionInvestmentProjectManagementPage = currentLocation.pathname === UrlCollection.ProjectManagement || currentLocation.pathname === UrlCollection.MapProjectManagement;
    const currentIsHousingProjectManagementPage = currentLocation.pathname === UrlCollection.HousingProjectManagement || currentLocation.pathname === UrlCollection.MapHousingProjectManagement;
    const currentIsLandUsePLanningManagementPage = currentLocation.pathname === UrlCollection.LandUsePLanningManagement;
    const currentIsTrafficPlanningManagementPage = currentLocation.pathname === UrlCollection.TrafficPlanningManagement;
    const currentIsHomePage = currentLocation.pathname === "/";
    const currentIsPlanningAnnouncementProcessPage =
        currentLocation.pathname === UrlCollection.PlanningAnnouncementProcess;
    const currentIsSliderPage = currentLocation.pathname === UrlCollection.Slider;
    const currentIsMapDataPage =
        currentLocation.pathname === UrlCollection.MapData;
    const currentIsNewsPage = currentLocation.pathname === UrlCollection.News;
    const currentIsDocumentManagementPage = currentLocation.pathname === UrlCollection.DocumentManagement;
    const currentIsAdministratorPages =
        !currentIsHomePage &&
        !currentIsPlanningAnnouncementProcessPage &&
        !currentIsSliderPage &&
        !currentIsMapDataPage &&
        !currentIsNewsPage &&
        !currentIsDocumentManagementPage &&
        !currentIsConstructionInvestmentProjectManagementPage &&
        !currentIsHousingProjectManagementPage &&
        !currentIsTrafficPlanningManagementPage &&
        !currentIsLandUsePLanningManagementPage;
    const isShowQHDT = currentLocation.pathname.includes("/qhdt");
    const isShowHeThong = currentLocation.pathname.includes("/he-thong");

    return (
        <span>
            {
                clientSetting && (
                    <ul style={{ background: clientSetting.color }}
                        className={
                            "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" +
                            (isToggleSidebar ? " toggled" : "")
                        }
                        id="accordionSidebar"
                    >
                        <a
                            className="sidebar-brand d-flex align-items-center justify-content-center"
                            href="/"
                        >
                            <div className="sidebar-brand-icon">
                                <img
                                    src={process.env.PUBLIC_URL + "/logo.png"}
                                    alt="Logo"
                                    width="50"
                                />
                            </div>
                            {
                                //src={require("../../assets/images/logo.png")}
                                clientSetting && (
                                    <div className="sidebar-brand-text">{clientSetting?.adminTitle}</div>
                                )
                            }

                        </a>

                        <hr className="sidebar-divider my-0" />

                        {isShowQHDT && (
                            <>
                                {/* <li className="nav-item">
                                    <Link className={`nav-link ${currentIsHomePage && "is-active"}`} to="/">
                                        <HomeIcon fontSize="small" className="mr-2" />
                                        <span>Trang chủ</span>
                                    </Link>
                                </li> */}

                                <hr className="sidebar-divider mb-0" />

                                <li className="nav-item">
                                    <Link
                                        className={`nav-link ${currentIsPlanningAnnouncementProcessPage && "is-active"
                                            }`}
                                        to={UrlCollection.PlanningAnnouncementProcess}
                                    >
                                        <AssignmentIcon fontSize="small" className="mr-2" />
                                        <span>Quản lý quy hoạch</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={`nav-link ${currentIsLandUsePLanningManagementPage && "is-active"
                                            }`}
                                        to={UrlCollection.LandUsePLanningManagement}
                                    >
                                        <AssignmentIcon fontSize="small" className="mr-2" />
                                        <span>Quản lý quy hoạch, kế hoạch sử dụng đất</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={`nav-link ${currentIsTrafficPlanningManagementPage && "is-active"
                                            }`}
                                        to={UrlCollection.TrafficPlanningManagement}
                                    >
                                        <AssignmentIcon fontSize="small" className="mr-2" />
                                        <span>Quản lý quy hoạch giao thông</span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={`nav-link collapsed ${currentIsConstructionInvestmentProjectManagementPage && "is-active"}`}
                                        to=""
                                        data-toggle="collapse"
                                        data-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                    >
                                        <div style={{ paddingRight: '5px' }}>
                                            <AssignmentIcon fontSize="small" className="mr-2" />
                                            <span>Quản lý dự án đầu tư xây dựng</span>
                                        </div>
                                        <div style={{ alignSelf: 'center', display: 'flex' }}>
                                            <FontAwesomeIcon icon={faChevronRight} className="float-right" />
                                            <FontAwesomeIcon icon={faChevronDown} className="float-right" />
                                        </div>
                                    </Link>

                                    <div
                                        id="collapseOne"
                                        className="collapse"
                                        aria-labelledby="headingTwo"
                                        data-parent="#accordionSidebar"
                                    >
                                        <div className="bg-white py-2 collapse-inner rounded">
                                            <LinkAdministratorItems
                                                icon={ListIcon}
                                                pageLink={UrlCollection.ProjectManagement}
                                                title="Danh sách dự án"
                                            />

                                            <LinkAdministratorItems
                                                icon={MapIcon}
                                                pageLink={UrlCollection.MapProjectManagement}
                                                title="Bản đồ"
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={`nav-link collapsed ${currentIsHousingProjectManagementPage && "is-active"}`}
                                        to=""
                                        data-toggle="collapse"
                                        data-target="#collapseSecond"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                    >
                                        <div style={{ paddingRight: '5px' }}>
                                            <AssignmentIcon fontSize="small" className="mr-2" />
                                            <span>Quản lý dự án nhà ở và bất động sản</span>
                                        </div>
                                        <div style={{ alignSelf: 'center', display: 'flex' }}>
                                            <FontAwesomeIcon icon={faChevronRight} className="float-right" />
                                            <FontAwesomeIcon icon={faChevronDown} className="float-right" />
                                        </div>
                                    </Link>

                                    <div
                                        id="collapseSecond"
                                        className="collapse"
                                        aria-labelledby="headingTwo"
                                        data-parent="#accordionSidebar"
                                    >
                                        <div className="bg-white py-2 collapse-inner rounded">
                                            <LinkAdministratorItems
                                                icon={ListIcon}
                                                pageLink={UrlCollection.ListHousingProject}
                                                title="Dự án nhà ở"
                                            />
                                            <LinkAdministratorItems
                                                icon={ListIcon}
                                                pageLink={UrlCollection.ListRealEstateProject}
                                                title="Dự án BĐS"
                                            />
                                            <LinkAdministratorItems
                                                icon={ListIcon}
                                                pageLink={UrlCollection.ListConstructionProject}
                                                title="Công trình BĐS"
                                            />
                                            <LinkAdministratorItems
                                                icon={ListIcon}
                                                pageLink={UrlCollection.ListRealEstateExchange}
                                                title="Sàn giao dịch BĐS"
                                            />
                                            <LinkAdministratorItems
                                                icon={MapIcon}
                                                pageLink={UrlCollection.MapHousingProjectManagement}
                                                title="Bản đồ"
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        className={`nav-link ${currentIsNewsPage && "is-active"}`}
                                        to={UrlCollection.News}
                                    >
                                        <PostAddIcon fontSize="small" className="mr-2" />
                                        <span>Tin tức</span>
                                    </Link>
                                </li>
                            </>
                        )}

                        {isShowHeThong && (
                            <li className="nav-item">
                                <Link
                                    className={`nav-link ${currentIsAdministratorPages && "is-active"}`}
                                    to=""
                                    data-toggle="collapse"
                                    data-target="#collapseTwo"
                                    aria-expanded="true"
                                    aria-controls="collapseTwo"
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                    <div style={{ paddingRight: '5px' }}>
                                        <SettingsIcon fontSize="small" className="mr-2" />
                                        <span>Administrator</span>
                                    </div>
                                    <div style={{ alignSelf: 'center', display: 'flex' }}>
                                        <FontAwesomeIcon icon={faChevronRight} className="float-right mt-1" />
                                        <FontAwesomeIcon icon={faChevronDown} className="float-right mt-1" />
                                    </div>
                                </Link>

                                <div
                                    id="collapseTwo"
                                    className="collapse show"
                                    aria-labelledby="headingTwo"
                                    data-parent="#accordionSidebar"
                                >
                                    <div className="bg-white py-2 collapse-inner rounded">
                                        <LinkAdministratorItems
                                            icon={EmailIcon}
                                            pageLink={UrlCollection.EmailTemplate}
                                            title="Email Template"
                                        />

                                        <LinkAdministratorItems
                                            icon={AllInboxIcon}
                                            pageLink={UrlCollection.EmailGenerated}
                                            title="Khởi tạo email"
                                        />

                                        <LinkAdministratorItems
                                            icon={ContactPhoneIcon}
                                            pageLink={UrlCollection.ContactManagement}
                                            title="Liên hệ"
                                        />

                                        <LinkAdministratorItems
                                            icon={PeopleIcon}
                                            pageLink={UrlCollection.RoleManagement}
                                            title="Chức vụ"
                                        />

                                        <LinkAdministratorItems
                                            icon={AccountBoxIcon}
                                            pageLink={UrlCollection.UserManagement}
                                            title="Quản lý người dùng"
                                        />

                                        <LinkAdministratorItems
                                            icon={() => (
                                                <img
                                                    className="mr-2 mt-n1"
                                                    src={require("../../assets/icon/group-links.png")}
                                                    alt="commune"
                                                />
                                            )}
                                            pageLink={UrlCollection.UnitManagement}
                                            title="Quản lý đơn vị"
                                        />

                                        <LinkAdministratorItems
                                            icon={() => (
                                                <img
                                                    className="mr-2 mt-n1"
                                                    src={require("../../assets/icon/commune.png")}
                                                    alt="commune"
                                                />
                                            )}
                                            pageLink={UrlCollection.StreetManagement}
                                            title="Quản lý đường"
                                        />

                                        <LinkAdministratorItems
                                            icon={() => (
                                                <img
                                                    className="mr-2 mt-n1"
                                                    src={require("../../assets/icon/commune.png")}
                                                    alt="commune"
                                                />
                                            )}
                                            pageLink={UrlCollection.CommuneManagement}
                                            title="Quản lý xã/phường"
                                        />

                                        <LinkAdministratorItems
                                            icon={() => (
                                                <img
                                                    className="mr-2 mt-n1"
                                                    src={require("../../assets/icon/district.png")}
                                                    alt="district"
                                                />
                                            )}
                                            pageLink={UrlCollection.DistrictManagement}
                                            title="Quản lý quận/huyện"
                                        />

                                        <LinkAdministratorItems
                                            icon={() => (
                                                <img
                                                    className="mr-2 mt-n1"
                                                    src={require("../../assets/icon/province.png")}
                                                    alt="province"
                                                />
                                            )}
                                            pageLink={UrlCollection.ProvinceManagement}
                                            title="Quản lý tỉnh/thành phố"
                                        />
                                        <LinkAdministratorItems
                                            icon={ViewComfyIcon}
                                            pageLink={UrlCollection.LayerStyle}
                                            title="Quản lý style layer"
                                        />
                                        <LinkAdministratorItems
                                            icon={() => (
                                                <img
                                                    className="mr-2 mt-n1"
                                                    src={require("../../assets/icon/group-links.png")}
                                                    alt="group-links"
                                                />
                                            )}
                                            pageLink={UrlCollection.LinkGroup}
                                            title="Danh sách nhóm"
                                        />

                                        <LinkAdministratorItems
                                            icon={() => (
                                                <img
                                                    className="mr-2 mt-n1"
                                                    src={require("../../assets/icon/link.png")}
                                                    alt="link"
                                                />
                                            )}
                                            pageLink={UrlCollection.ServiceLink}
                                            title="Danh sách liên kết"
                                        />

                                        <LinkAdministratorItems
                                            icon={HistoryIcon}
                                            pageLink={UrlCollection.UserLogHistory}
                                            title="Nhật ký người dùng"
                                        />

                                        <LinkAdministratorItems
                                            icon={ViewComfyIcon}
                                            pageLink={UrlCollection.LandType}
                                            title="Quản lý loại đất"
                                        />

                                        <LinkAdministratorItems
                                            icon={ViewAgendaIcon}
                                            pageLink={UrlCollection.LandTypeDetail}
                                            title="Quản lý chi tiết loại đất"
                                        />
                                        <LinkAdministratorItems
                                            icon={ViewComfyIcon}
                                            pageLink={UrlCollection.Place}
                                            title="Quản lý địa điểm"
                                        />


                                        <LinkAdministratorItems
                                            icon={ViewAgendaIcon}
                                            pageLink={UrlCollection.PlaceType}
                                            title="Quản lý loại địa điểm"
                                        />
                                        <LinkAdministratorItems
                                            icon={ViewAgendaIcon}
                                            pageLink={UrlCollection.PlaceKind}
                                            title="Quản lý chi tiết địa điểm"
                                        />
                                        <LinkAdministratorItems
                                            icon={ViewAgendaIcon}
                                            pageLink={UrlCollection.ProjectKind}
                                            title="Quản lý loại dự án"
                                        />
                                        <LinkAdministratorItems
                                            icon={ViewAgendaIcon}
                                            pageLink={UrlCollection.ProjectType}
                                            title="Quản lý chi tiết loại dự án"
                                        />
                                        <LinkAdministratorItems
                                            icon={CallToActionIcon}
                                            pageLink={UrlCollection.OpinionForm}
                                            title="Tạo form ý kiến"
                                        />

                                        <LinkAdministratorItems
                                            icon={TableChartIcon}
                                            pageLink={UrlCollection.TableStructure}
                                            title="Sửa cấu trúc bảng"
                                        />
                                        <LinkAdministratorItems
                                            icon={CallToActionIcon}
                                            pageLink={UrlCollection.Slider}
                                            title="Slider"
                                        />
                                    </div>
                                </div>
                            </li>
                        )}

                        <hr className="sidebar-divider d-none d-md-block" />

                        <div className="text-center d-none d-md-inline">
                            <button
                                className="rounded-circle border-0"
                                id="sidebarToggle"
                                onClick={onToggleSidebar}
                            >
                                <ChevronLeftIcon
                                    fontSize="small"
                                    className={"text-white" + (isToggleSidebar ? " d-none" : "")}
                                />
                                <ChevronRightIcon
                                    fontSize="small"
                                    className={"text-white" + (!isToggleSidebar ? " d-none" : "")}
                                />
                            </button>
                        </div>
                    </ul>
                )
            }

        </span>
    );
}

const mapStateToProps = state => ({
    settings: state.clientSetting.clientSetting,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getSettings: clientSettingAction.getSettings
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)