import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import { 
    defaultGeoStyler, 
    mappingGeoStylerName, 
    mappingGeoStylerRuleName, 
    mappingGeoStylerRule,
    mappingGeoStylerRemoveRule,
    mappingGeoStylerAddRule,
} from "./mapping-data";

const service = new Service();

// export const GetListHousingProject = (data) => {
//     const params = new URLSearchParams();
//     params.append("PageIndex", data.pageIndex);
//     params.append("PageSize", data.pageSize);
//     data.sorting && params.append("Sorting", data.sorting);
//     data.keyword && params.append("ProjectName", data.keyword);

//     return service
//         .get(ApiUrl.GetListAll_HousingProject, params)
//         .then((res) => {
//             return res;
//         })
//         .catch((err) => {
//             throw err;
//         });
// };

// export const GetDetailHousingProject = (id) => {
//     return service
//         .get(ApiUrl.GetDetail_HousingProject(id))
//         .then((res) => {
//             return res;
//         })
//         .catch((err) => {
//             throw err;
//         });
// };

const GEOSTYLER = "GEOSTYLER";
const GEOSTYLERNAME = "GEOSTYLERNAME";
const GEOSTYLERRULE = "GEOSTYLERRULE";

const onChangeGeoStyler = (data) => ({
    type: GEOSTYLER,
    data: data,
});

const onChangeGeoStylerName = (data) => ({
    type: GEOSTYLERNAME,
    data: data,
});

const onChangeGeoStylerRule = (rule, index) => ({
    type: GEOSTYLERRULE,
    rule: rule,
    index: index,
});

const initialState = {
    geoStyler: defaultGeoStyler,
};

export default function GeoStylerReducer(state = initialState, action) {
    switch (action.type) {
        case GEOSTYLER: {
            return { ...state, geoStyler: action.data }
        }
        case GEOSTYLERNAME: {
            return { ...state, geoStyler: mappingGeoStylerName(state.geoStyler, action.data) }
        }
        case GEOSTYLERRULE: {
            return { ...state, geoStyler: mappingGeoStylerRule(state.geoStyler, action.rule, action.index) }
        }
        default: return state
    }
}

export const setGeoStyler = (data) => {
    return (dispatch) => {
        dispatch(onChangeGeoStyler(data));
    };
};

export const setGeoStylerName = (name) => {
    return (dispatch) => {
        dispatch(onChangeGeoStylerName(name));
    };
};

export const setGeoStylerRule = (rule, index) => {
    return (dispatch) => {
        dispatch(onChangeGeoStylerRule(rule, index));
    };
};