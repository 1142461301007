import React from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

function ColorPicker({ color, setColor, displayColorPicker, setDisplayColorPicker }) {

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChange = (newColor) => {
        setColor(newColor.hex);
    };

    const styles = reactCSS({
        'default': {
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    return (
        <div className='position-relative'>
            {displayColorPicker ? (
                <div style={styles.popover}>
                    <div style={styles.cover} onClick={handleClose} />
                    <SketchPicker color={color} onChange={handleChange} />
                </div>
            ) : null}
        </div>
    );
}

export default ColorPicker;
