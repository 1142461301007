const SAVE_BASE_MAP = 'BASE_MAP/SAVE_BASE_MAP';
const SAVEOPENLAYERCONTROLLER = 'OPENLAYER/SAVEOPENLAYERCONTROLLER';
const SAVEOPENLAYERFEATURESSELECTED = 'OPENLAYER/SAVEFEATURESELECTED';

const CreateSaveBaseMapAction = data => ({ type: SAVE_BASE_MAP, data: data })
const CreateSaveOpenlayerControllerAction = data => ({ type: SAVEOPENLAYERCONTROLLER, data: data })
const CreateSaveOpenlayerFeatureSelectedAction = data => ({ type: SAVEOPENLAYERFEATURESSELECTED, data: data })

export const CreateBaseMap = (baseMap) => {
    return dispatch => {
        dispatch(CreateSaveBaseMapAction(baseMap))
    }
}

export const CreateOpenlayerController = (handleFunction) => {
    return dispatch => {
        dispatch(CreateSaveOpenlayerControllerAction(handleFunction))
    }
}

export const SaveOpenlayerFeaturesSelected = (featuresSelected) => {
    return dispatch => {
        dispatch(CreateSaveOpenlayerFeatureSelectedAction(featuresSelected))
    }
}

const InitState = {
    baseMap: null,
    openLayerController: null,
    featuresSelected: [],
}

export default function MapOpenLayerReducer(state = InitState, action) {
    switch (action.type) {
        case SAVE_BASE_MAP: return { ...state, baseMap: action.data }
        case SAVEOPENLAYERCONTROLLER: return { ...state, openLayerController: action.data }
        case SAVEOPENLAYERFEATURESSELECTED: return { ...state, featuresSelected: action.data }
        default: return state
    }
}