import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Checkbox,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  changeAlias,
  APIUrlDefault,
} from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import * as action from "../../../redux/store/list-real-estate-exchange/list-real-estate-exchange.store";
//--- Action
import Autocomplete from "@material-ui/lab/Autocomplete";
import FileManagement from "../../../components/file_management/file_management";
//---- Datetime
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function AddRealEstateExchangeManagement(props) {
  const classes = useStyles();
  const [districtId, setDistrictId] = useState();
  const [isStatus, setIsStatus] = useState(false);
  const [isExistBusinessCode, setIsExistBusinessCode] = useState(false);
  const businessCode = document.getElementById("businessCode");

  //File
  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);
  const [zipFiles, setZipFiles] = useState([]);
  const [zipFilesTemp, setZipFilesTemp] = useState([]);
  const [isShowZip, setShowZip] = useState(false);
  const [zipName, setZipName] = useState(null);
  //GallerysImage
  const [isShow, setShow] = useState(false);

  const {
    isOpen,
    onClose,
    onSuccess,
    GetListRealEstateExchange,
    rowsPerPage,
    showLoading,
    districtModels,
    openCreateByShapeFile,
  } = props;
  const { register, handleSubmit, errors, setValue, clearErrors, setError } =
    useForm({
      mode: "all",
      reValidateMode: "onBlur",
    });
  // Datetime
  const today = new Date();
  const fromDate = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getDate(),
    today.getHours(),
    today.getMinutes(),
    0,
    0
  );
  const toDate = new Date(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getDate() + 1,
    today.getHours(),
    today.getMinutes(),
    0,
    0
  );

  const onSubmit = (data) => {
    if (!data) {
      return;
    } else if (isExistBusinessCode) {
      if (businessCode) businessCode.focus();
      return;
    } else {
      let formData = new FormData();
      formData.append("businessCode", data?.businessCode);
      formData.append("realEstateExchangeName", data?.realEstateExchangeName);
      formData.append("legalRepresentative", data?.legalRepresentative);
      formData.append("address", data?.address);
      formData.append("dateEstablishment", data?.dateEstablishment);
      formData.append("dateDissolution", data?.dateDissolution);
      formData.append("status", isStatus);
      formData.append("districtId", districtId || data?.districtId);
      if (zipFiles && zipFiles.length > 0)
        formData.append("shapeFileId", zipFiles[0].fileId);
      formData.append("DocumentUploadId", files[0].fileId);
      action
        .Create(formData)
        .then((result) => {
          if (result && result.content && result.content.status === true) {
            GetListRealEstateExchange(1, rowsPerPage);
            showLoading(false);
            onSuccess();
            ShowNotification(
              viVN.Success.CreateSuccess,
              NotificationMessageType.Success
            );
          }
        })
        .catch((err) => {
          onSuccess();
          showLoading(false);
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
        });
    }
  };

  let timeout = 0;
  const handleCheckCodeExist = (event) => {
    let value = event;

    if (!value) {
      setError("businessCode", { type: "required" });
    } else {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        CheckExistedRealEstateExchange(value);
      }, 100);
    }
  };
  const CheckExistedRealEstateExchange = (businessCode) => {
    action
      .CheckExistedBusinessCode(businessCode)
      .then((res) => {
        if (res && res.content && res.content.status) {
          clearErrors("businessCode");
          setIsExistBusinessCode(false);
        } else {
          setError("businessCode", { type: "validate" });
          setIsExistBusinessCode(true);
        }
      })
      .catch((err) => {
        setError("businessCode", { type: "validate" });
        setIsExistBusinessCode(true);
      });
  };

  const handleOnchangeDistrict = (event, newValue) => {
    if (newValue) {
      setDistrictId(newValue.id);
    }
  };
  const onOpenSelectZipFile = () => {
    setShowZip(true);
    setZipFilesTemp(zipFiles);
    setZipName(null);
  };

  const onCloseSelectZipFile = () => {
    setShowZip(false);
    setZipFiles(zipFilesTemp);
  };

  const onSaveSelectZipFile = () => {
    setShowZip(false);
    setZipName(zipFiles[0].fileName);
  };
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const onSetStartDate = (time) => {
    setStartDate(time);
    if (time.getTime() < endDate.getTime()) {
      clearErrors(["dateEstablishment", "dateDissolution"]);
    } else {
      setError("dateEstablishment", { type: "required" });
    }
  };

  const onSetEndDate = (time) => {
    setEndDate(time);
    if (time.getTime() > startDate.getTime()) {
      clearErrors(["dateEstablishment", "dateDissolution"]);
    } else {
      setError("dateDissolution", { type: "required" });
    }
  };
  //Image gallerys
  const onOpenSelectFile = () => {
    setShow(true);
    setFilesTemp(files);
  };
  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
  };
  const onSaveSelectFile = () => {
    setShow(false);
  };
  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Thêm sàn giao dịch BDS</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className="pt-4 pb-2" dividers>
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">
                  Mã doanh nghiệp<span className="required"></span>
                </label>
                <TextField
                  fullWidth
                  type="text"
                  name="businessCode"
                  className="w-100"
                  inputRef={register({
                    required: true,
                    validate: handleCheckCodeExist,
                  })}
                  onChange={(e) => {
                    setValue("businessCode", changeAlias(e.target.value), {
                      shouldDirty: true,
                    });
                  }}
                  id="businessCode"
                  error={
                    (errors.businessCode &&
                      errors.businessCode.type === "required") ||
                    (errors.businessCode &&
                      errors.businessCode.type === "validate")
                  }
                  variant="outlined"
                  size="small"
                />
                {errors.businessCode &&
                  errors.businessCode.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                {errors.businessCode &&
                  errors.businessCode.type === "validate" && (
                    <span className="error">Mã dự án đã tồn tại</span>
                  )}
              </div>

              <div className="col-6">
                <label className="text-dark">
                  Tên sàn BDS<span className="required"></span>
                </label>

                <TextField
                  fullWidth
                  inputRef={register({ required: true })}
                  type="text"
                  name="realEstateExchangeName"
                  error={
                    errors.realEstateExchangeName &&
                    errors.realEstateExchangeName.type === "required"
                  }
                  variant="outlined"
                  size="small"
                />
                {errors.realEstateExchangeName &&
                  errors.realEstateExchangeName.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">Đại diện pháp nhân</label>

                <TextField
                  fullWidth
                  type="text"
                  name="legalRepresentative"
                  className="w-100"
                  inputRef={register}
                  variant="outlined"
                  size="small"
                />
              </div>

              <div className="col-6">
                <label className="text-dark">Địa chỉ</label>

                <TextField
                  fullWidth
                  inputRef={register}
                  type="text"
                  name="address"
                  error={errors.address}
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">Ngày thành lập</label>
                <div className="w-100">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      id="dateEstablishment"
                      name="dateEstablishment"
                      onChange={(date) => date && onSetStartDate(date)}
                      format="dd/MM/yyyy"
                      value={startDate}
                      fullWidth
                      showTodayButton={true}
                      inputRef={register}
                      variant="outlined"
                      size="small"
                      error={errors.dateEstablishment}
                    />
                  </MuiPickersUtilsProvider>
                  {errors.dateEstablishment && (
                    <span className="error">Phải nhỏ hơn ngày giải thể</span>
                  )}
                </div>
              </div>

              <div className="col-6">
                <label className="text-dark">Ngày giải thể</label>
                <div className="w-100">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      id="dateDissolution"
                      name="dateDissolution"
                      onChange={(date) => date && onSetEndDate(date)}
                      format="dd/MM/yyyy"
                      value={endDate}
                      inputRef={register}
                      fullWidth
                      showTodayButton={true}
                      error={errors.dateDissolution}
                    />
                    {errors.dateDissolution && (
                      <span className="error">Phải lớn hơn ngày thành lập</span>
                    )}
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">Tình trạng hoạt động</label>
                <FormControl
                  fullWidth
                  error={!!errors.status}
                  variant="outlined"
                  size="small"
                >
                  <InputLabel htmlFor="status">Chọn hoạt động</InputLabel>
                  <Select
                    label="Chọn hoạt động"
                    defaultValue={isStatus}
                    onChange={(event) => setIsStatus(event.target.value)}
                  >
                    <MenuItem value={true}>{"Đang hoạt động"}</MenuItem>
                    <MenuItem value={false}>{"Không hoạt động"}</MenuItem>
                  </Select>
                  {errors.status && (
                    <FormHelperText>{errors.status.message}</FormHelperText>
                  )}
                </FormControl>
              </div>
              {districtModels && districtModels.length > 0 && (
                <div className="col-6">
                  <label className="text-dark">Quận / huyện</label>
                  <Autocomplete
                    id="district"
                    options={districtModels}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    onChange={(event, newValue) =>
                      handleOnchangeDistrict(event, newValue)
                    }
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Chọn quận huyện (*)"
                        name={`districtId`}
                        inputRef={register}
                        size="small"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="form-group row">
              <div className="col-12">
              <label className="text-dark">Ảnh<span className="required"></span></label>
                {!isShow &&
                  files &&
                  files.length > 0 &&
                  files.map((item) => (
                    <div key={item.fileName} style={{ width: "150px" }}>
                      <img
                        src={APIUrlDefault + item.filePreview}
                        alt={item.fileName}
                        className="img-fluid mb-2"
                        style={{
                          width: "auto",
                          height: "auto",
                          maxWidth: "100%",
                          maxHeight: "100%",
                        }}
                      />
                    </div>
                  ))}
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onOpenSelectFile}
                  >
                    Chọn file
                  </Button>
                  <TextField
                    inputRef={register({ required: true })}
                    type="hidden"
                    name="image"
                    value={
                      (files && files.length > 0 && files[0].fileName) || ""
                    }
                  />
                  {errors.image && errors.image.type === "required" && (
                    <p className="error">Trường này là bắt buộc</p>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-6">
                <label className="text-dark">Shape File</label>
                <span className="required"></span>
                <TextField
                  fullWidth
                  type="text"
                  name="shpName"
                  value={zipName}
                  placeholder="Hãy tải file zip lên."
                  disabled
                  variant="outlined"
                  size="small"
                  className="w-100"
                  inputRef={register({
                    required: true,
                  })}
                  error={errors.shpName && errors.shpName.type === "required"}
                />
                {errors.shpName && errors.shpName.type === "required" && (
                  <span className="error">Trường này là bắt buộc</span>
                )}

                <div className="mt-2">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onOpenSelectZipFile}
                  >
                    Chọn shape file
                  </Button>
                </div>
              </div>
            </div>
          </DialogContent>

          <DialogActions className="border-top">
            <Button
              type="button"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {isShowZip && (
        <Dialog
          onClose={onCloseSelectZipFile}
          open={isShowZip}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectZipFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={zipFiles}
              setFiles={setZipFiles}
              acceptedFiles={["zip"]}
              isShowLeft={false}
              isShowAddFolder={false}
              isShowDownload={true}
              // typeFile={'ZIP'}
              //cgisId={cgisId}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectZipFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {zipFiles && zipFiles.length > 0 && zipFiles !== zipFilesTemp && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectZipFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
      {isShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isShow}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={files}
              setFiles={setFiles}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
              filterExtension={"png"}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Thoát
            </Button>
            {files && files.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
