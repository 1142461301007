import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    makeStyles,
    Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { listPropertiesFilterOptions } from '../../constant';

const RenderRow = (props) => {
    const { data, index, handleChangeRuleSymbolizer } = props;
    const [isbetween, setIsbetween] = useState('')
    
    return (
        <>
            <div className='filter_row'>
                <input
                    type='text'
                    className="input_text mw-250"
                    defaultValue={data[1] || ''}
                    placeholder='Enter Attribute'
                // onChange={e => handleChangeRuleName(e.target.value, index)}
                />
                {data[0] == '<=x<=' && (
                    <input
                        type='text'
                        className="input_text"
                        value={data?.name}
                        placeholder='Enter value'
                    // onChange={e => handleChangeRuleName(e.target.value, index)}
                    />
                )}
                <Select
                    className="select_filter"
                    options={listPropertiesFilterOptions}
                    defaultValue={{ value: 1, label: '==' }}
                    value={listPropertiesFilterOptions.filter(x => x.label == data[0])}
                // onChange={handleChangeKind}
                />
                <input
                    type='text'
                    className="input_text"
                    defaultValue={data[2] || ''}
                    placeholder='Enter value'
                // onChange={e => handleChangeRuleName(e.target.value, index)}
                />
            </div>
        </>
    )
}

export default RenderRow;