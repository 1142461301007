import React, { useState, useEffect } from 'react';
import MapOpenlayer from './map-openlayer';

const InitMap = (props) => {

    const [mapLib, setMapLib] = useState({ id: 0 });

    useEffect(() => {
        getMapLib();
    }, []);

    const getMapLib = () => {
        const { Map, View, Feature, MapBrowserEvent, Overlay } = require('ol');
        const { getArea, getLength } = require('ol/sphere');
        const VectorSource = require('ol/source/Vector').default;
        const { OSM, XYZ, TileWMS, ImageWMS } = require('ol/source');
        const { GeoJSON, WKT } = require('ol/format');
        const ImageLayer = require('ol/layer/Image').default;
        const CircleStyle = require('ol/style/Circle').default;
        const { Fill, Stroke, Style, Icon, Text } = require('ol/style');
        const defaultControls = require('ol/control').defaults();
        const createBox = require('ol/interaction/Draw').default;
        const VectorLayer = require('ol/layer/Vector').default;
        const VectorImageLayer = require('ol/layer/VectorImage').default;
        const { Layer } = require('ol/layer');
        const TileLayer = require('ol/layer/Tile').default;
        const { register } = require('ol/proj/proj4');
        const { transform, Projection } = require('ol/proj');
        const { Select, DragBox, Draw } = require('ol/interaction');
        const { MousePosition, Rotate } = require('ol/control');
        const { createStringXY } = require('ol/coordinate');
        const { LineString, Polygon, Point } = require('ol/geom');
        const { unByKey } = require('ol/Observable');
        const { click } = require('ol/events/condition');
        const GeometryType = require('ol/geom/GeometryType').default;

        const mapLib = {
            id: 1,
            Map: Map,
            View: View,
            Feature: Feature,
            MapBrowserEvent: MapBrowserEvent,
            Overlay: Overlay,
            createStringXY: createStringXY,
            getArea: getArea,
            getLength: getLength,
            OSM: OSM,
            XYZ: XYZ,
            VectorSource: VectorSource,
            TileWMS: TileWMS,
            ImageWMS: ImageWMS,
            GeoJSON: GeoJSON,
            WKT: WKT,
            ImageLayer: ImageLayer,
            CircleStyle: CircleStyle,
            Fill: Fill,
            Stroke: Stroke,
            Style: Style,
            Icon: Icon,
            defaultControls: defaultControls,
            createBox: createBox,
            Layer: Layer,
            VectorLayer: VectorLayer,
            VectorImageLayer: VectorImageLayer,
            TileLayer: TileLayer,
            register: register,
            Projection: Projection,
            Select: Select,
            DragBox: DragBox,
            Draw: Draw,
            MousePosition: MousePosition,
            Rotate: Rotate,
            GeometryType: GeometryType,
            LineString: LineString,
            Polygon: Polygon,
            Point: Point,
            unByKey: unByKey,
            click: click,
            transform: transform,
            Text: Text
        };
        setMapLib(mapLib);
    };

    return (
        <div>
            {mapLib && mapLib.id == 1 && (
                <MapOpenlayer
                    mapLib={mapLib}
                    {...props}
                />
            )}
        </div>
    )
}

export default InitMap;