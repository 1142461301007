import React, { useState } from 'react'
import { TableCell, TableRow, IconButton, Tooltip } from "@material-ui/core";
import DragIcon from "@material-ui/icons/DragHandle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import Symbolizer from './symbolizer';
import FilterField from './filter-field/filter-field';
import { listSymbolizer } from '../constant';
import CanvasSymbolizer from './draw-symbolizer/canvas-symbolizer';
import { getFilterTextFromRule } from './function-handle/function-handle';

const Rule = (props) => {
    const {
        data,
        index,
        handleChangeRuleName,
        handleChangeScale,
        handleRemoveRule,
        handleChangeRuleSymbolizer,
        handleChangeRuleFilter,
    } = props;

    const [isOpenSymbolizerSetting, setIsOpenSymbolizerSetting] = useState(false);
    const [isOpenFilterEditor, setIsOpenFilterEditor] = useState(false);

    return (
        <>
            {data && (
                <>
                    <TableRow hover tabIndex={-1} key={data.id}>
                        <TableCell>
                            <DragIcon className="text-primary cursor-pointer" />
                        </TableCell>
                        {/* <TableCell className='text-center'>
                            <input
                                type='checkbox'
                                className="cursor-pointer"
                                checked={data.isCheck}
                            />
                        </TableCell> */}
                        <TableCell className='text-center'>
                            <div className='sbl_icon cursor-pointer' onClick={() => setIsOpenSymbolizerSetting(true)}>
                                {data.symbolizers[0]?.kind && (
                                    <CanvasSymbolizer symbolizer={data.symbolizers[0]} />
                                )}
                            </div>
                        </TableCell>
                        <TableCell className="">
                            <input
                                type='text'
                                className="input_text"
                                value={data?.name}
                                onChange={e => handleChangeRuleName(e.target.value, index)}
                            />
                        </TableCell>
                        <TableCell className="position-relative">
                            <input
                                disabled
                                type='text'
                                className="input_text"
                                value={getFilterTextFromRule(data)}
                            />
                            <div className='btn_edit_filter' onClick={() => setIsOpenFilterEditor(true)}>
                                <EditIcon className="text-primary" />
                            </div>
                        </TableCell>
                        <TableCell className="">
                            <input
                                type='text'
                                className="input_text"
                                value={data?.scaleDenominator?.min ? `${data?.scaleDenominator?.min}` : ''}
                                onChange={e => handleChangeScale({ min: parseInt(e.target.value), max: data?.scaleDenominator?.max || '' }, index)}
                            />
                        </TableCell>
                        <TableCell>
                            <input
                                type='text'
                                className="input_text"
                                value={data?.scaleDenominator?.max ? `${data?.scaleDenominator?.max}` : ''}
                                onChange={e => handleChangeScale({ max: parseInt(e.target.value), min: data?.scaleDenominator?.min || '' }, index)}

                            />
                        </TableCell>
                        <TableCell align="right" className="text-nowrap">
                            <Tooltip title="Xóa">
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => handleRemoveRule(index)}
                                >
                                    <DeleteIcon className="text-danger" />
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                    {isOpenSymbolizerSetting && (
                        <Symbolizer
                            isOpen={isOpenSymbolizerSetting}
                            onClose={() => setIsOpenSymbolizerSetting(false)}
                            data={data.symbolizers[0]}
                            index={index}
                            handleChangeRuleSymbolizer={handleChangeRuleSymbolizer}
                        />
                    )}
                    {isOpenFilterEditor && (
                        <FilterField
                            isOpen={isOpenFilterEditor}
                            onClose={() => setIsOpenFilterEditor(false)}
                            data={data?.filter}
                            index={index}
                            handleChangeRuleSymbolizer={handleChangeRuleSymbolizer}
                        />
                    )}
                </>
            )}
        </>
    )
}

export default Rule;
