import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Configs } from "../../../common/config";

//--- Material Table
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";

//--- Material Icon
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Badge from "@material-ui/core/Badge";
import VisibilityIcon from "@material-ui/icons/Visibility";

//--- Material Control
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { UrlCollection } from "../../../common/url-collection";
import history from "../../../common/history";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: window.outerHeight - 365,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const headCells = [
  { id: "fileCode", hideSortIcon: false, label: "Mã hồ sơ" },
  { id: "constructionCode ", hideSortIcon: false, label: "Mã công trình" },
  { id: "projectName ", hideSortIcon: false, label: "Tên công trình BDS" },
  { id: "realEstateProjectName ", hideSortIcon: false, label: "Tên dự án BDS" },
  //{ id: "constructionRealEstateTypeName ", hideSortIcon: false, label: "Loại công trình BDS" },
  // { id: "numberFloor ", hideSortIcon: false, label: "Số tầng" },
  // { id: "numberHouse ", hideSortIcon: false, label: "Số phòng" },
  { id: "totalArea ", hideSortIcon: false, label: "Tổng mức diện tích" },
  // { id: "averageSalesPrice ", hideSortIcon: false, label: "Giá bán trung bình" },
  // { id: "averageRentalPrice ", hideSortIcon: false, label: "Giá thuê trung bình" },
  { id: "legalDocument", hideSortIcon: false, label: "Danh sách tài liệu" },
  { id: "actions", hideSortIcon: true, label: "" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            className="pt-3 pb-3 text-nowrap"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={headCell.hideSortIcon ? true : false}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ListConstructionRealEstateManagement(props) {
  const {
    editAction,
    detailAction,
    constructionRealEstateModels,
    totalItemCount,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    GetListConstructionRealEstate,
    name,
    order,
    page,
    rowsPerPage,
    orderBy,
    deleteAction,
  } = props;

  //--- Config table
  const classes = useStyles();

  //--- Handle sort, change page, change row per page
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    let sort = isAsc ? "desc" : "asc";
    let sortExpression = property + " " + sort;
    GetListConstructionRealEstate(page + 1, rowsPerPage, sortExpression, name);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    let sortExpression = orderBy + " " + order;
    GetListConstructionRealEstate(
      newPage + 1,
      rowsPerPage,
      sortExpression,
      name
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    let sortExpression = orderBy + " " + order;
    GetListConstructionRealEstate(1, event.target.value, sortExpression, name);
  };
  const goLegalDocumentView = (projectId, Type) => {
    history.push(
      `${UrlCollection.ListLegalDocumentManagement}?projectId=${projectId}&type=${Type}`
    );
  };
  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      constructionRealEstateModels.length - page * rowsPerPage
    );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size="small" stickyHeader>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={constructionRealEstateModels.length}
            />
            <TableBody>
              {constructionRealEstateModels &&
              constructionRealEstateModels.length > 0 ? (
                constructionRealEstateModels.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell>{row.fileCode}</TableCell>
                      <TableCell>{row.constructionCode}</TableCell>
                      <TableCell>{row.projectName}</TableCell>
                      <TableCell>{row.realEstateProjectName}</TableCell>
                      {/* //  <TableCell>{row.constructionRealEstateTypeName}</TableCell> */}
                      {/* <TableCell>{row.numberFloor }</TableCell>
                      <TableCell>{row.numberHouse }</TableCell> */}
                      <TableCell>{row.totalArea}</TableCell>
                      {/* <TableCell>{row.averageSalesPrice }</TableCell>
                      <TableCell>{row.averageRentalPrice }</TableCell> */}
                      <TableCell align="center">
                        <Tooltip title="Danh sách tài liệu">
                          <IconButton
                            onClick={() =>
                              goLegalDocumentView(
                                row.id,
                                "construction_real_estate"
                              )
                            }
                          >
                            <Badge
                              color="primary"
                              badgeContent={row.legalDocumentsCount}
                              showZero
                            >
                              <AssignmentIcon />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      {/* <TableCell align="center" className="text-nowrap w-10">
                        <Tooltip title="Xem">
                          <IconButton
                            aria-label="view"
                            onClick={() => detailAction(row.id)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell> */}
                      <TableCell align="right" className="text-nowrap">
                        <Tooltip title="Xem">
                          <IconButton
                            aria-label="view"
                            onClick={() => detailAction(row.id)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Sửa">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editAction(row.id)}
                          >
                            <EditIcon className="text-primary" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Xóa">
                          <IconButton
                            aria-label="delete"
                            onClick={() => deleteAction(row.id)}
                          >
                            <DeleteIcon className="text-danger" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover tabIndex={-1}>
                  <TableCell colSpan={9} className="text-center">
                    Không có dữ liệu
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 0 }}>
                  <TableCell
                    colSpan={4}
                    style={{ padding: 0, borderBottom: 0 }}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {totalItemCount && totalItemCount > 0 ? (
          <TablePagination
            rowsPerPageOptions={Configs.DefaultPageSizeOption}
            component="div"
            count={totalItemCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={"Số hàng mỗi trang"}
            labelDisplayedRows={({ from, to, count }) => {
              return "" + from + "-" + to + " trong " + count || "";
            }}
          />
        ) : (
          ""
        )}
      </Paper>
    </div>
  );
}
