import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

//--- Material Control
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Checkbox,
} from "@material-ui/core";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType, APIUrlDefault } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import * as action from "../../../redux/store/construction-real-estate-management/construction-real-estate-management.store.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as helper from "../../../utils/helper.js";
import DateFnsUtils from "@date-io/date-fns";
import viLocale from "date-fns/locale/vi";
import dateformat from "dateformat";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import FileManagement from "../../../components/file_management/file_management";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function DetailConstructionRealEstateManagement(props) {
  const classes = useStyles();
  const {
    isOpen,
    onClose,
    onSuccess,
    GetListConstructionRealEstate,
    rowsPerPage,
    showLoading,
    constructionRealEstateId,
  } = props;
  const { register, handleSubmit, errors, setValue, clearErrors, setError } =
    useForm({
      mode: "all",
      reValidateMode: "onBlur",
    });
  const [isImageShow, setImageShow] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);

  const [lookupRealEstateProjectModel, setLookupRealEstateProjectModel] =
    useState([]);
  const [realEstateProjectId, setRealEstateProjectId] = useState();

  const [
    lookupConstructionRealEstateTypeModel,
    setLookupConstructionRealEstateTypeModel,
  ] = useState([]);
  const [constructionRealEstateTypeId, setConstructionRealEstateTypeId] =
    useState();
  const [legalDocumentsId, setLegalDocumentsId] = useState([]);
  const [lookupLegalDocumentsModel, setLookupLegalDocumentsModel] = useState(
    []
  );
  const [constructionRealEstate, setConstructionRealEstate] = useState();

  //File
  const [zipFiles, setZipFiles] = useState([]);
  const [zipFilesTemp, setZipFilesTemp] = useState([]);
  const [isShowZip, setShowZip] = useState(false);
  const [zipName, setZipName] = useState(null);
  //Images
  const [isShow, setShow] = useState(false);

  const [fileSelected, setFileSelected] = useState(constructionRealEstate?.avatar);

  const [gallerySelected, setGallerySelected] = useState([]);
  const [isGalleryShow, setGalleryShow] = useState(false);
  const [gallery, setGallery] = useState(constructionRealEstate?.galleryFiles);
  const [galleryTemp, setGalleryTemp] = useState([]);
  useEffect(() => {
    showLoading(true);
    onGetData();
    action
      .GetDetail(constructionRealEstateId)
      .then((res) => {
        if (res && res.content) {
          setConstructionRealEstate(res.content);
          setRealEstateProjectId(res.content.realEstateProjectId);
          setConstructionRealEstateTypeId(
            res.content.constructionRealEstateTypeId
          );
          setLegalDocumentsId(res.content.legalDocumentsId);
          setZipFiles(res.content.shapeFileId ? [res.content.shapeFileId] : []);
          setFiles([res.content?.avatar]);
          setGallery(res.content?.galleryFiles);
          setGallerySelected(res.content?.galleryFiles);
        }
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      helper.LookupRealEstateProject(),
      helper.LookupConstructionRealEstateType(),
      helper.LookupLegalDocument(),
    ])
      .then((res) => {
        const [
          lookupRealEstateProject,
          lookupConstructionRealEstateType,
          lookupLegalDocument,
        ] = res;
        lookupRealEstateProject &&
          lookupRealEstateProject.content &&
          setLookupRealEstateProjectModel(lookupRealEstateProject.content);

        lookupConstructionRealEstateType &&
          lookupConstructionRealEstateType.content &&
          setLookupConstructionRealEstateTypeModel(
            lookupConstructionRealEstateType.content
          );

        lookupLegalDocument &&
          lookupLegalDocument.content &&
          setLookupLegalDocumentsModel(lookupLegalDocument.content);
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };
  const onSubmit = (data) => {
    if (!data) {
      return;
    } else {
      let formData = new FormData();
      formData.append("id", constructionRealEstateId);
      formData.append("fileCode", constructionRealEstate.fileCode);
      formData.append("constructionCode", data?.constructionCode);
      formData.append(
        "realEstateProjectId",
        realEstateProjectId || data?.realEstateProjectId
      );
      formData.append("projectName", data?.projectName);
      formData.append(
        "constructionRealEstateTypeId",
        constructionRealEstateTypeId || data?.constructionRealEstateTypeId
      );
      formData.append("numberFloor", data?.numberFloor);
      formData.append("numberHouse", data?.numberHouse);
      formData.append("totalArea", data?.totalArea);
      formData.append("averageSalesPrice", data?.averageSalesPrice);
      formData.append("averageRentalPrice", data?.averageRentalPrice);
      // legalDocumentsId.forEach((id, index) => {
      //   formData.append(`legalDocumentsId[${index}]`, id);
      // });
      //formData.append("shpFile", shapeFile);
      if (zipFiles && zipFiles.length > 0) {
        formData.append("shapeFileId", zipFiles[0]?.fileId || zipFiles[0]);
      }
      action
        .Update(formData)
        .then((result) => {
          if (result && result.content && result.content.status === true) {
            GetListConstructionRealEstate(1, rowsPerPage);
            showLoading(false);
            onSuccess();
            ShowNotification(
              viVN.Success.UpdateSuccess,
              NotificationMessageType.Success
            );
          }
        })
        .catch((err) => {
          showLoading(false);
          onSuccess();
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
        });
    }
  };
  const handleOnchangeRealEstateProject = (event, newValue) => {
    if (newValue) {
      setRealEstateProjectId(newValue.id);
    }
  };
  const handleOnchangeContructionRealEstateType = (event, newValue) => {
    if (newValue) {
      setConstructionRealEstateTypeId(newValue.id);
    }
  };
  const handleOnchangeLegalDocument = (event, newValue) => {
    setLegalDocumentsId(newValue.map((option) => option.id));
  };

  const onOpenSelectZipFile = () => {
    setShowZip(true);
    setZipFilesTemp(zipFiles);
    setZipName(null);
  };

  const onCloseSelectZipFile = () => {
    setShowZip(false);
    setZipFiles(zipFilesTemp);
  };

  const onSaveSelectZipFile = () => {
    setShowZip(false);
    setZipName(zipFiles[0].fileName);
  };
  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Chi tiết công trình BDS</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {constructionRealEstate && (
            <DialogContent className="pt-4 pb-2">
              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">
                    Mã hồ sơ<span className="required"></span>
                  </label>
                  <TextField
                    fullWidth
                    type="text"
                    disabled
                    name={`fileCode`}
                    defaultValue={constructionRealEstate.fileCode}
                    className="w-100"
                    inputRef={register({
                      required: true,
                      // validate: handleCheckCodeExist,
                    })}
                    id="fileCode"
                    error={
                      (errors.fileCode &&
                        errors.fileCode.type === "required") ||
                      (errors.fileCode && errors.fileCode.type === "validate")
                    }
                    variant="outlined"
                    size="small"
                  />
                  {errors.fileCode && errors.fileCode.type === "required" && (
                    <span className="error">Trường này là bắt buộc</span>
                  )}
                </div>

                <div className="col-6">
                  <label className="text-dark">
                    Mã công trình<span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({ required: true })}
                    defaultValue={constructionRealEstate.constructionCode}
                    disabled
                    type="text"
                    name={`constructionCode`}
                    error={
                      errors.constructionCode &&
                      errors.constructionCode.type === "required"
                    }
                    variant="outlined"
                    size="small"
                  />
                  {errors.constructionCode &&
                    errors.constructionCode.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
              </div>
              <div className="form-group row">
                {lookupRealEstateProjectModel &&
                  lookupRealEstateProjectModel.length > 0 && (
                    <div className="col-6">
                      <label className="text-dark">Tên dự án BDS</label>
                      <Autocomplete
                        options={lookupRealEstateProjectModel}
                        defaultValue={{
                          id: constructionRealEstate.realEstateProjectId,
                          name: constructionRealEstate.realEstateProjectName,
                        }}
                        disabled
                        getOptionLabel={(option) =>
                          typeof option === "string" ? option : option.name
                        }
                        onChange={(event, newValue) =>
                          handleOnchangeRealEstateProject(event, newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            name={`realEstateProjectId`}
                            inputRef={register}
                            {...params}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </div>
                  )}
                <div className="col-6">
                  <label className="text-dark">Tên công trình BDS</label>

                  <TextField
                    fullWidth
                    type="text"
                    name={`projectName`}
                    defaultValue={constructionRealEstate.projectName}
                    disabled
                    className="w-100"
                    inputRef={register}
                    variant="outlined"
                    size="small"
                  />
                </div>
              </div>

              <div className="form-group row">
                {lookupConstructionRealEstateTypeModel &&
                  lookupConstructionRealEstateTypeModel.length > 0 && (
                    <div className="col-6 col-md-6 col-lg-6">
                      <label className="text-dark">Loại công trình BDS</label>
                      <Autocomplete
                        options={lookupConstructionRealEstateTypeModel}
                        defaultValue={{
                          id: constructionRealEstate.constructionRealEstateTypeId,
                          name: constructionRealEstate.constructionRealEstateTypeName,
                        }}
                        disabled
                        getOptionLabel={(option) =>
                          typeof option === "string" ? option : option.name
                        }
                        onChange={(event, newValue) =>
                          handleOnchangeContructionRealEstateType(
                            event,
                            newValue
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            name={`constructionRealEstateTypeId`}
                            inputRef={register}
                            {...params}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      />
                    </div>
                  )}
                <div className="col-6">
                  <label className="text-dark">Số tầng</label>

                  <TextField
                    fullWidth
                    type="number"
                    name={`numberFloor`}
                    defaultValue={constructionRealEstate.numberFloor}
                    disabled
                    className="w-100"
                    inputRef={register}
                    variant="outlined"
                    size="small"
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">Số phòng</label>

                  <TextField
                    fullWidth
                    inputRef={register}
                    defaultValue={constructionRealEstate.numberHouse}
                    disabled
                    type="number"
                    name={`numberHouse`}
                    variant="outlined"
                    size="small"
                  />
                </div>
                <div className="col-6">
                  <label className="text-dark">Tổng mức diện tích</label>
                  <TextField
                    fullWidth
                    type="number"
                    defaultValue={constructionRealEstate.totalArea}
                    disabled
                    name={`totalArea`}
                    className="w-100"
                    inputRef={register}
                    variant="outlined"
                    size="small"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">Giá bán trung bình</label>

                  <TextField
                    fullWidth
                    inputRef={register}
                    type="number"
                    defaultValue={constructionRealEstate.averageSalesPrice}
                    disabled
                    name={`averageSalesPrice`}
                    variant="outlined"
                    size="small"
                  />
                </div>
                <div className="col-6">
                  <label className="text-dark">Giá thuê trung bình</label>

                  <TextField
                    fullWidth
                    inputRef={register}
                    defaultValue={constructionRealEstate.averageRentalPrice}
                    disabled
                    type="number"
                    name={`averageRentalPrice`}
                    variant="outlined"
                    size="small"
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="text-dark">Thư viện ảnh</label>
                <div className="list__img d-flex flex-wrap mt-1">
                  {!isGalleryShow &&
                    gallerySelected &&
                    gallerySelected.length > 0 &&
                    gallerySelected.map((item) => (
                      <div
                        key={item.fileName}
                        className="file_item mr-3"
                        style={{ width: "80px" }}
                      >
                        <img
                          src={require("../../../assets/icon/default.svg")}
                          alt={item.fileName}
                          title={item.fileName}
                          className="img-fluid mb-2"
                          disabled
                          style={{
                            width: "auto",
                            height: "auto",
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                        />
                        <p className="file_name">{item.fileName}</p>
                      </div>
                    ))}
                </div>
              </div>
              <div className="form-group">
                <div className="col-12">
                  <label className="text-dark">
                    Ảnh<span className="required"></span>
                  </label>
                  {!isShow &&
                    files &&
                    files.length > 0 &&
                    files.map((item) => (
                      <div key={item?.fileName} style={{ width: "150px" }}>
                        <img
                          src={APIUrlDefault + item?.filePreview}
                          alt={item?.fileName}
                          className="img-fluid mb-2"
                          disabled
                          style={{
                            width: "auto",
                            height: "auto",
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                        />
                      </div>
                    ))}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-6">
                  <label className="text-dark">Shape File</label>
                  <TextField
                    fullWidth
                    type="text"
                    name="shpName"
                    value={
                      zipName ||
                      constructionRealEstate?.shapeFileName?.fileName ||
                      ""
                    }
                    placeholder="Hãy tải file zip lên."
                    disabled
                    variant="outlined"
                    size="small"
                  />
                </div>
              </div>
            </DialogContent>
          )}
        </form>
      </Dialog>
    </div>
  );
}
