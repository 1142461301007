import React, { useEffect, useState } from 'react'
import FileManagement from "../../../file_management/file_management";
import { NotificationMessageType, APIUrlDefault } from "../../../../utils/configuration";

import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ImgIcon from "@material-ui/icons/Image";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

const PointSymbolizer = (props) => {
  const classes = useStyles();

  const [isShow, setShow] = useState(false);
  const [files, setFiles] = useState([]);
  const [filePath, setFilePath] = useState('')
  const [filesTemp, setFilesTemp] = useState([]);

  useEffect(() => {
    if (files && files.length > 0) {
      setFilePath(APIUrlDefault + files[0].filePreview)
    } else { setFilePath('') }
  }, [files])
  const onOpenSelectFile = () => {
    setShow(true);
    setFilesTemp(files);
  };

  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
    console.log('fdjsgfjksdf', filesTemp)
  };

  const onSaveSelectFile = () => {
    console.log(files);
    setShow(false);
  };

  const handleChangeImgPath = (e) => {
    const value = e.target.value;
    setFilePath(value);
  }
  return (
    <div className='field_symbolizer_block'>
      <div>
        <div className='field_symbolizer'>
          <label className="text-dark">Source Image</label>
          {filePath && (
            <img
              src={filePath ?? filePath}
              alt='Kiểm tra lại dường dẫn ảnh'
              className="img-fluid mb-2"
              style={{
                width: "120px",
                height: "120px",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            />
          )}
          <div className='upload_img'>
            <input
              type='text'
              className="input_text"
              value={filePath}
              onChange={handleChangeImgPath}
            />
            <div className='btn_upload_img'>
              <Tooltip title="Ảnh từ hệ thống">
                <ImgIcon onClick={onOpenSelectFile} />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className='field_symbolizer'>
          <label className="text-dark">Size</label>
          <input
            type='number'
            className="input_text"
          />
        </div>
        <div className='field_symbolizer'>
          <label className="text-dark">Offset X</label>
          <input
            type='number'
            className="input_text"
          />
        </div>
        <div className='field_symbolizer'>
          <label className="text-dark">Offset Y</label>
          <input
            type='number'
            className="input_text"
          />
        </div>
        <div className='field_symbolizer'>
          <label className="text-dark">Rotation</label>
          <input
            type='number'
            className="input_text"
          />
        </div>
        <div className='field_symbolizer'>
          <label className="text-dark">Opacity</label>
          <input
            type='number'
            className="input_text"
            min="0.0"
            max="1.0"
            step="0.1"
          />
        </div>
        {
          isShow && (
            <Dialog
              onClose={onCloseSelectFile}
              open={isShow}
              fullWidth={true}
              maxWidth="md"
              className="dialog-preview-form"
            >
              <DialogTitle disableTypography>
                <Typography variant="h6">Quản lý file</Typography>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={onCloseSelectFile}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <FileManagement
                  files={files}
                  setFiles={setFiles}
                  acceptedFiles={["jpeg", "png", "jpg", "gif"]}
                />
              </DialogContent>

              <DialogActions>
                <Button
                  type="button"
                  onClick={onCloseSelectFile}
                  variant="contained"
                  startIcon={<CloseIcon />}
                >
                  Hủy
                </Button>
                {files && files.length > 0 && (
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={onSaveSelectFile}
                  >
                    Lưu
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          )
        }
      </div>
    </div>
  )
}

export default PointSymbolizer
