import React, { useEffect, useState } from 'react'
import FileManagement from "../../../file_management/file_management";
import { NotificationMessageType, APIUrlDefault } from "../../../../utils/configuration";
import Select from 'react-select'

import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    makeStyles,
    Checkbox,
    Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ImgIcon from "@material-ui/icons/Image";
import { listSymbol } from '../../constant';
import ColorPicker from '../color-picker/color-picker';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(1),
    },
}));

const PointMarkSymbolizer = (props) => {
    const classes = useStyles();

    const [isShow, setShow] = useState(false);
    const [files, setFiles] = useState([]);
    const [filePath, setFilePath] = useState('')
    const [filesTemp, setFilesTemp] = useState([]);
    const [color, setColor] = useState('');
    const [openColorPicker, setOpenColorPicker] = useState(false);
    const [strokecolor, setStrokeColor] = useState('');
    const [openStrokeColorPicker, setOpenStrokeColorPicker] = useState(false);

    const [symbolSelected, setSymbolSelected] = useState({
        id: 1,
        name: 'Circle',
    })

    useEffect(() => {
        if (files && files.length > 0) {
            setFilePath(APIUrlDefault + files[0].filePreview)
        } else { setFilePath('') }
    }, [files])
    const onOpenSelectFile = () => {
        setShow(true);
        setFilesTemp(files);
    };

    const onCloseSelectFile = () => {
        setShow(false);
        setFiles(filesTemp);
        console.log('fdjsgfjksdf', filesTemp)
    };

    const onSaveSelectFile = () => {
        console.log(files);
        setShow(false);
    };

    const handleChangeSymbol = (data) => {
        setSymbolSelected(data);
    }

    return (
        <div className='field_symbolizer_block'>
            <div>
                <div className='field_symbolizer'>
                    <label className="text-dark">Symbol</label>
                    <Select
                        options={listSymbol}
                        value={symbolSelected}
                        onChange={handleChangeSymbol}
                    />
                </div>
                <div className='field_symbolizer'>
                    <label className="text-dark">Offset X</label>
                    <input
                        type='number'
                        className="input_text"
                    />
                </div>
                <div className='field_symbolizer'>
                    <label className="text-dark">Offset Y</label>
                    <input
                        type='number'
                        className="input_text"
                    />
                </div>
                <div className='field_symbolizer'>
                    <label className="text-dark">Rotation</label>
                    <input
                        type='number'
                        className="input_text"
                    />
                </div>
                <div className='field_symbolizer'>
                    <label className="text-dark">Fill-Color</label>
                    <div
                        className='color_picker_field'
                        onClick={() => setOpenColorPicker(true)}
                        style={{ background: `${color}` }}
                    >
                        <span>{color ? color : `Pick color`}</span>
                    </div>
                    {openColorPicker && (
                        <ColorPicker
                            color={color}
                            setColor={setColor}
                            displayColorPicker={openColorPicker}
                            setDisplayColorPicker={setOpenColorPicker}
                        />
                    )}
                </div>
                <div className='field_symbolizer'>
                    <label className="text-dark">Opacity</label>
                    <input
                        type='number'
                        className="input_text"
                        min="0.0"
                        max="1.0"
                        step="0.1"
                    />
                </div>
                <div className='field_symbolizer'>
                    <label className="text-dark">Fill-Opacity</label>
                    <input
                        type='number'
                        className="input_text"
                        min="0.0"
                        max="1.0"
                        step="0.1"
                    />
                </div>
                <div className='field_symbolizer'>
                    <label className="text-dark">Stroke-Color</label>
                    <div
                        className='color_picker_field'
                        onClick={() => setOpenStrokeColorPicker(true)}
                        style={{ background: `${strokecolor}` }}
                    >
                        <span>{strokecolor ? strokecolor : `Pick color`}</span>
                    </div>
                    {openStrokeColorPicker && (
                        <ColorPicker
                            color={strokecolor}
                            setColor={setStrokeColor}
                            displayColorPicker={openStrokeColorPicker}
                            setDisplayColorPicker={setOpenStrokeColorPicker}
                        />
                    )}
                </div>
                <div className='field_symbolizer'>
                    <label className="text-dark">Stroke-Width</label>
                    <input
                        type='number'
                        className="input_text"
                    />
                </div>
                <div className='field_symbolizer'>
                    <label className="text-dark">Stroke-Opacity</label>
                    <input
                        type='number'
                        className="input_text"
                        min="0.0"
                        max="1.0"
                        step="0.1"
                    />
                </div>
                <div className='field_symbolizer'>
                    <label className="text-dark">Rotation</label>
                    <input
                        type='number'
                        className="input_text"
                    />
                </div>
                {
                    isShow && (
                        <Dialog
                            onClose={onCloseSelectFile}
                            open={isShow}
                            fullWidth={true}
                            maxWidth="md"
                            className="dialog-preview-form"
                        >
                            <DialogTitle disableTypography>
                                <Typography variant="h6">Quản lý file</Typography>
                                <IconButton
                                    aria-label="close"
                                    className={classes.closeButton}
                                    onClick={onCloseSelectFile}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent dividers>
                                <FileManagement
                                    files={files}
                                    setFiles={setFiles}
                                    acceptedFiles={["jpeg", "png", "jpg", "gif"]}
                                />
                            </DialogContent>

                            <DialogActions>
                                <Button
                                    type="button"
                                    onClick={onCloseSelectFile}
                                    variant="contained"
                                    startIcon={<CloseIcon />}
                                >
                                    Hủy
                                </Button>
                                {files && files.length > 0 && (
                                    <Button
                                        type="button"
                                        color="primary"
                                        variant="contained"
                                        startIcon={<SaveIcon />}
                                        onClick={onSaveSelectFile}
                                    >
                                        Lưu
                                    </Button>
                                )}
                            </DialogActions>
                        </Dialog>
                    )
                }
            </div>
        </div>
    )
}

export default PointMarkSymbolizer
