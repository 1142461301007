import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    makeStyles,
    Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { listPropertiesFilterOptions } from '../../constant';
import RenderRow from './render-row';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(1),
    },
}));
const FilterField = (props) => {
    const { isOpen, onClose, data, index, handleChangeRuleSymbolizer } = props;
    const classes = useStyles();
    const [filterData, setFilterData] = useState(data);

    useEffect(() => {
        if (data) console.log('data', data);
    }, [])
    
    useEffect(() => {
        if (filterData === data) console.log('data', data);
    }, [filterData])
    
    return (
        <div>
            <Dialog className="h-100" open={isOpen} onClose={onClose} maxWidth="md">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">Filter Editor</Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="symbolizer_wraper">
                    <RenderRow
                        {...props}
                    />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default FilterField;