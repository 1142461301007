import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../core/app.store";
import * as gsActions from "../../redux/store/geo-style/geo-style.store";

import { listHeader, listRuleDemo, listSymbolizer } from './constant';

//--- Material Table
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//--- Components
import Rule from './components/rule';
import { geoStylerDeno } from '../style-editor/constant';
import {
    mappingGeoStylerRuleName,
    mappingGeoStylerRuleScaleDenominator,
    mappingGeoStylerRemoveRule,
    mappingGeoStylerAddRule,
    mappingGeoStylerRuleFilter,
    mappingGeoStylerRuleSymbolizer,
} from '../../redux/store/geo-style/mapping-data';

import "./graphical-editor.scss";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    tableContainer: {
        maxHeight: window.outerHeight - 330,
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

const GraphicalEditor = (props) => {
    const {
        geoStylerObject,
        setGeoStylerObject,
        setGeoStylerRule,
    } = props;

    const classes = useStyles();
    // useEffect(() => { console.log('geoStylerObject', geoStylerObject) }, [geoStylerObject.rules[0]])

    const handleChangeName = (e) => {
        props.setGeoStylerName(e.target.value)
    }

    const handleChangeRuleSymbolizer = (symbolizer, index) => {
        if(!symbolizer.kind) return;
        setGeoStylerObject(mappingGeoStylerRuleSymbolizer(geoStylerObject, symbolizer, index))
    }

    const handleChangeRuleFilter = (filter, index) => {
        setGeoStylerObject(mappingGeoStylerRuleFilter(geoStylerObject, filter, index))
    }

    const handleChangeRuleName = (name, index) => {
        setGeoStylerObject(mappingGeoStylerRuleName(geoStylerObject, name, index))
    }
    const handleChangeScale = (saleDenominator, index) => {
        setGeoStylerObject(mappingGeoStylerRuleScaleDenominator(geoStylerObject, saleDenominator, index))
    }

    const handleAddRule = () => {
        setGeoStylerObject(mappingGeoStylerAddRule(geoStylerObject))
    }

    const handleRemoveRule = (index) => {
        setGeoStylerObject(mappingGeoStylerRemoveRule(geoStylerObject, index))
    }

    return (
        <div className='graphical_editor_wraper'>
            <div className='graphical_editor_header'>
                <h2>GEOSTYLER Graphical Editor</h2>
            </div>
            <div className='graphical_editor_name'>
                Name:
                <input
                    type="text"
                    className="form-control"
                    value={geoStylerObject?.name}
                    placeholder="Nhập tên"
                    onChange={e => handleChangeName(e)}
                // onChange={e => props.setGeoStyler(geoStylerDeno)}
                />
            </div>
            <div className='graphical_editor_table'>
                <div className={classes.root}>
                    <Paper className={classes.paper}>
                        <TableContainer className={classes.tableContainer}>
                            <Table className={classes.table} size="small" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {listHeader.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                className={``}
                                            >
                                                {headCell.title}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {geoStylerObject && geoStylerObject.rules.length > 0 ? (
                                        geoStylerObject.rules.map((row, index) => (
                                            <Rule
                                                data={row}
                                                index={index}
                                                handleChangeRuleName={handleChangeRuleName}
                                                handleChangeScale={handleChangeScale}
                                                handleRemoveRule={handleRemoveRule}
                                                handleChangeRuleFilter={handleChangeRuleFilter}
                                                handleChangeRuleSymbolizer={handleChangeRuleSymbolizer}
                                            />
                                        ))
                                    ) : (
                                        <TableRow hover tabIndex={-1}>
                                            <TableCell colSpan={8} className="text-center">
                                                Không có dữ liệu
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow>
                                        <TableCell colSpan={8} className="" onClick={() => handleAddRule()}>
                                            <div className='cursor-pointer'>{`+ Add Rule`}</div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
    geoStylerObject: state.geoStyler.geoStyler,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
            setGeoStylerObject: gsActions.setGeoStyler,
            setGeoStylerName: gsActions.setGeoStylerName,
            setGeoStylerRule: gsActions.setGeoStylerRule,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GraphicalEditor);

