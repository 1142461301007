import { drawSymbolizer } from "./draw";

import React, { useEffect, useRef } from 'react';

const CanvasSymbolizer = ({ symbolizer }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    // Dọn sạch canvas trước khi vẽ
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    
    // Vẽ symbolizer lên canvas
    drawSymbolizer(ctx, symbolizer);
  }, [symbolizer]);

  return <canvas ref={canvasRef} width={50} height={50} />;
};

export default CanvasSymbolizer;