const getRandomHexColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

const getDefaultRule = () => {
    return {
        name: `Rule ${Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000}`,
        symbolizers: [
            {
                color: getRandomHexColor(),
                kind: "Line",
                width: 5
            }
        ]
    }
}

export const defaultGeoStyler = {
    name: "GeoStyler Demo",
    rules: [getDefaultRule()]
}

export const mappingGeoStylerName = (geoStyler, name) => {
    return { ...geoStyler, name: name }
}

export const mappingGeoStylerAddRule = (geoStyler) => {
    console.log(geoStyler, geoStyler)
    return { ...geoStyler, rules: [...geoStyler.rules, getDefaultRule()] }
}

export const mappingGeoStylerRemoveRule = (geoStyler, index) => {
    if (!geoStyler.rules || !geoStyler.rules.length) return;
    let gs = geoStyler;
    gs.rules.splice(index, 1)
    return { ...gs }
}

export const mappingGeoStylerRule = (geoStyler, rule, index) => {
    if (!geoStyler.rules.length) return;
    return {
        ...geoStyler,
        rules: geoStyler.rules.map((item, idx) =>
            idx == index ? rule : item
        )
    };
}

export const mappingGeoStylerRuleSymbolizer = (geoStyler, symbolizers, index) => {
    if (!geoStyler.rules.length) return;
    console.log('symbolizers', symbolizers)
    return {
        ...geoStyler,
        rules: geoStyler.rules.map((rule, idx) =>
            idx == index ? { ...rule, symbolizers: [symbolizers] } : rule
        )
    };
}

export const mappingGeoStylerRuleFilter = (geoStyler, filter, index) => {
    if (!geoStyler.rules.length) return;
    return {
        ...geoStyler,
        rules: geoStyler.rules.map((rule, idx) =>
            idx == index ? { ...rule, filter: filter } : rule
        )
    };
}

export const mappingGeoStylerRuleName = (geoStyler, name, index) => {
    if (!geoStyler.rules.length) return;
    return {
        ...geoStyler,
        rules: geoStyler.rules.map((rule, idx) =>
            idx == index ? { ...rule, name: name } : rule
        )
    };
}

export const mappingGeoStylerRuleScaleDenominator = (geoStyler, scaleDenominator, index) => {
    if (!geoStyler.rules.length) return;
    return {
        ...geoStyler,
        rules: geoStyler.rules.map((rule, idx) =>
            idx == index ? { ...rule, scaleDenominator: scaleDenominator } : rule
        )
    };
}