/**
 * Vẽ LineSymbolizer lên canvas
 * @param {CanvasRenderingContext2D} ctx - Ngữ cảnh (context) của canvas
 * @param {Object} symbolizer - Đối tượng LineSymbolizer từ GeoStyler
 */
const drawLineSymbolizer = (ctx, symbolizer) => {
    const { color, stroke, opacity, strokeWidth, width } = symbolizer;

    ctx.beginPath();
    ctx.moveTo(0, 40); // Điểm bắt đầu đường
    ctx.lineTo(30, 30); // Điểm kết thúc đường
    ctx.lineTo(40, 30); // Điểm kết thúc đường
    ctx.lineTo(50, 15);
    ctx.globalAlpha = opacity || 1;
    ctx.strokeStyle = color || '#000000'; // Màu stroke
    ctx.lineWidth = width || 5; // Độ rộng stroke
    ctx.stroke();
};

/**
* Vẽ PolygonSymbolizer lên canvas
* @param {CanvasRenderingContext2D} ctx - Ngữ cảnh (context) của canvas
* @param {Object} symbolizer - Đối tượng PolygonSymbolizer từ GeoStyler
*/
const drawPolygonSymbolizer = (ctx, symbolizer) => {
    const { fill, color, fillOpacity, outlineWidth, outlineColor, graphicFill } = symbolizer;

    ctx.beginPath();
    ctx.moveTo(0, 0); // Điểm đầu tiên của hình đa giác
    ctx.lineTo(0, 50); // Điểm thứ hai
    ctx.lineTo(50, 50); // Điểm thứ ba
    ctx.lineTo(50, 0); // Điểm thứ tư
    ctx.closePath(); // Kết thúc hình đa giác
    ctx.globalAlpha = fillOpacity || 1;
    if (color) {
        ctx.fillStyle = color;
        ctx.fill();
    }

    if (outlineColor) {
        ctx.strokeStyle = outlineColor;
        ctx.lineWidth = outlineWidth || 2;
        ctx.stroke();
    }

    if (graphicFill?.image) {
        console.log('image', graphicFill?.image)
        const icon = new Image();
        const imgSize = graphicFill?.size ? parseFloat(graphicFill?.size) : 5;
        const spacing = 0;
        const rotate = graphicFill?.rotate ? parseFloat(graphicFill?.rotate) : 0;
        icon.src = graphicFill.image;

        icon.onload = function () {
            if (rotate) {
                for (let y = 0; y < 100; y += imgSize + spacing) {
                    for (let x = 0; x < 100; x += imgSize + spacing) {
                        ctx.save();

                        // Di chuyển hệ trục tọa độ đến tâm của icon
                        ctx.translate(x + imgSize / 2, y + imgSize / 2);

                        // Xoay canvas
                        ctx.rotate(rotate);
                        ctx.drawImage(icon, x, y, imgSize, imgSize);
                        ctx.restore();
                    }
                }
            } else {
                for (let y = 0; y < 100; y += imgSize + spacing) {
                    for (let x = 0; x < 100; x += imgSize + spacing) {
                        console.log('image', imgSize + spacing)
                        ctx.drawImage(icon, x, y, imgSize, imgSize);
                    }
                }
            }
            // ctx.drawImage(icon, (50 - imgSize) / 2, (50 - imgSize) / 2, imgSize, imgSize);
        };
    }
};

/**
* Vẽ PointSymbolizer lên canvas
* @param {CanvasRenderingContext2D} ctx - Ngữ cảnh (context) của canvas
* @param {Object} symbolizer - Đối tượng PointSymbolizer từ GeoStyler
*/
const drawPointSymbolizer = (ctx, symbolizer) => {
    const { color, fillOpacity, radius, strokeColor, strokeWidth } = symbolizer;

    ctx.beginPath();
    ctx.arc(25, 25, radius || 10, 0, 2 * Math.PI, false);
    ctx.globalAlpha = fillOpacity || 1;
    ctx.fillStyle = color || '#000000';
    ctx.strokeStyle = strokeColor;
    ctx.lineWidth = strokeWidth || 1;
    ctx.fill();
    ctx.stroke();

};

const drawIconSymbolizer = (ctx, symbolizer) => {
    const { opacity, size, image } = symbolizer;

    const icon = new Image();
    icon.src = image;

    icon.onload = function () {
        ctx.globalAlpha = opacity;
        ctx.drawImage(icon, (50 - size) / 2, (50 - size) / 2, size, size);
    };
}

/**
* Vẽ symbolizer lên canvas
* @param {CanvasRenderingContext2D} ctx - Ngữ cảnh (context) của canvas
* @param {Object} symbolizer - Symbolizer từ GeoStyler (Line, Polygon, hoặc Point)
*/
export const drawSymbolizer = (ctx, symbolizer) => {
    if (symbolizer.kind === 'Line') {
        drawLineSymbolizer(ctx, symbolizer);
    } else if (symbolizer.kind === 'Fill') {
        drawPolygonSymbolizer(ctx, symbolizer);
    } else if (symbolizer.kind === 'Mark') {
        drawPointSymbolizer(ctx, symbolizer);
    } else if (symbolizer.kind === 'Icon') {
        drawIconSymbolizer(ctx, symbolizer)
    }
};
