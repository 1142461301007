import SLDParser from "geostyler-sld-parser";
import OpenLayersParser from "geostyler-openlayers-parser";
import formatter from 'xml-formatter';
import { Fill, Stroke, Style, Icon, Text } from 'ol/style';

const sldParser = new SLDParser();
const olParser = new OpenLayersParser();

/**
 * @param {string} sld - Chuỗi SLD
 * @returns {Promise<Object>} - OpenLayers style object
 */
export const sldToOl = async (sld) => {
  try {
    // Chuyển đổi SLD sang GeoStyler style
    const { output: geostylerStyle } = await sldParser.readStyle(sld);
    console.log('geostylerStyle', geostylerStyle)
    // Chuyển đổi GeoStyler style sang OpenLayers style
    const { output: olStyle } = await olParser.writeStyle(geostylerStyle);
    console.log('olStyle', olStyle)

    return olStyle;
  } catch (error) {
    console.error("Error converting SLD to OL style:", error);
    throw error;
  }
};
/**
 * @param {object} olStyle - OpenLayers style object
 * @returns {Promise<string>} - SLD string
 */

export const sldToGS = async (sld) => {
  try {
    // Chuyển đổi SLD sang GeoStyler style
    const { output: geostylerStyle } = await sldParser.readStyle(sld);
    // Chuyển đổi GeoStyler style sang OpenLayers style
    console.log('geostylerStyle', geostylerStyle)
    return geostylerStyle;
  } catch (error) {
    console.error("Error converting SLD to OL style:", error);
    throw error;
  }
};

export const gStoSld = async (geoStylerStyle) => {
  try {
    // Chuyển đổi SLD sang GeoStyler style
    const { output: sld } = await sldParser.writeStyle(geoStylerStyle);
    // Chuyển đổi GeoStyler style sang OpenLayers style
    console.error("Error converting SLD to OL style:", sld);

    return handleFormatXml(sld);
  } catch (error) {
    console.error("Error converting SLD to OL style:", error);
    throw error;
  }
};

export const gsToOl = async (geoStylerStyle) => {
  try {
    // Chuyển đổi SLD sang GeoStyler style
    const { output: olStyle } = await olParser.writeStyle(geoStylerStyle);
    // Chuyển đổi GeoStyler style sang OpenLayers style

    return olStyle;
  } catch (error) {
    console.error("Error converting SLD to OL style:", error);
    throw error;
  }
};

export const getSymbolizerFromRule = async (rule) => {
  try {
    // Chuyển đổi SLD sang GeoStyler style
    const { output: olStyle } = await sldParser.writeStyle(rule);
    // Chuyển đổi GeoStyler style sang OpenLayers style

    return olStyle;
  } catch (error) {
    console.error("Error converting SLD to OL style:", error);
    throw error;
  }
}

export const getFilterTextFromRule = (rule) => {
  if (!rule.filter) {
    return '';
  }

  // Trích xuất các filter từ mỗi rule
  const filters = rule.filter[1] + ' ' + rule.filter[0] + ' ' + rule.filter[2];

  return filters;
}

export const handleFormatXml = (xmlInput) => {
  const formatted = formatter(xmlInput, {
    indentation: '  ', // Số khoảng trắng cho mỗi mức thụt đầu dòng
    collapseContent: true, // Gộp nội dung không cần xuống dòng
  });

  return formatted;
};

const hexToRgba = (hex, opacity = 1) => {
  // Remove the '#' if present
  if (!hex) return;
  hex = hex.replace('#', '');

  // If it's a shorthand hex color (3 digits), convert to 6 digits
  if (hex.length === 3) {
    hex = hex.split('').map(function (char) {
      return char + char;
    }).join('');
  }

  // Parse the hex values for red, green, and blue
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Return the RGBA color string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const geoStylerToOlStyle = (geoStyler) => {
  if (!geoStyler.rules || geoStyler.rules.length == 0) return;
  let listStyle = geoStyler.rules.map((rule, index) => {
    return RuleToOlStyle(rule, index);
  })
  return { ...geoStyler, listStyle };
}

export const RuleToOlStyle = (rule, index) => {
  switch (rule.symbolizers[0].kind) {
    case 'Icon':
      return { kind: rule.symbolizers[0].kind, index: index, style: convertIconStyle(rule.symbolizers[0], index) }
    case 'Mark':
      return { kind: rule.symbolizers[0].kind, index: index, style: convertMarkStyle(rule.symbolizers[0], index) }
    case 'Line':
      return { kind: rule.symbolizers[0].kind, index: index, style: convertLineStyle(rule.symbolizers[0], index) }
    case 'Fill':
      return { kind: rule.symbolizers[0].kind, index: index, style: convertFillStyle(rule.symbolizers[0], index) }
    default:
      return 'loi cmnr'
  }
}

const convertIconStyle = (symbolizer, index) => {
  const img = new Image();
  img.src = symbolizer.image;
  // img.width = symbolizer.size; // Set the width to 300 pixels
  // img.height = symbolizer.size; // Set the height to 200 pixels

  const style = new Style({
    image: new Icon({
      anchor: [0.5, (30 * index)],
      opacity: symbolizer?.opacity | 1,
      src: symbolizer.image,
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
    }),
  })
  return style;
}

const convertMarkStyle = (symbolizer, index) => {
  const style = {
    anchor: [0.5, (30 * index)],
    anchorXUnits: 'fraction',
    anchorYUnits: 'pixels',
    opacity: symbolizer?.opacity | 1,
    src: symbolizer.image,
  }
  return style;
}

const convertLineStyle = (symbolizer, index) => {
  const style = (new Style({
    stroke: new Stroke({
      color: hexToRgba(symbolizer.color, symbolizer?.opacity),
      width: symbolizer.width,
      lineCap: symbolizer?.cap,     // Kiểu dáng đầu đường: 'butt', 'round', 'square'
      lineJoin: symbolizer?.join,    // Kiểu dáng góc nối: 'miter', 'round', 'bevel'  
    }),
    anchor: [0.5, (30 * index)],
    anchorXUnits: 'fraction',
    anchorYUnits: 'pixels',
    opacity: symbolizer.opacity | 1,
    index: index
  }))
  return style;
}

const convertFillStyle = (symbolizer, index) => {
  let res = null
  if (symbolizer.graphicFill && symbolizer.graphicFill.image) {
    const img = new Image();
    img.src = 'https://openlayers.org/en/latest/examples/data/icon.png'; // Đường dẫn hình ảnh
    img.onload = () => {
      const pattern = document.createElement('canvas').getContext('2d').createPattern(img, 'repeat');

      // Tạo style cho polygon với fill là hình ảnh
      const polygonStyle = new Style({
        fill: new Fill({
          color: pattern, // Sử dụng CanvasPattern làm fill
        }),
        stroke: new Stroke({
          color: hexToRgba(symbolizer.outlineColor, symbolizer?.outlineOpacity),
          width: symbolizer?.outlineWidth,
        }),
        index: index,
        anchor: [0.5, (30 * index)],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',  
      });
      res = polygonStyle;
    };
  } else {
    const style = (new Style({
      fill: new Fill({
        color: hexToRgba(symbolizer.color, symbolizer?.fillOpacity),
      }),
      stroke: new Stroke({
        color: hexToRgba(symbolizer.outlineColor, symbolizer?.outlineOpacity),
        width: symbolizer?.outlineWidth,
      }),
      index: index,
      anchor: [0.5, (30 * index)],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
    }))
    return style;
  }
  return res
}