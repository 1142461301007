import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import FileManagement from "../../../file_management/file_management";
import { NotificationMessageType, APIUrlDefault } from "../../../../utils/configuration";

import {
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ImgIcon from "@material-ui/icons/Image";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDownIcon from "@material-ui/icons/ArrowDropDown";
import ColorPicker from '../color-picker/color-picker';
import { capJoinOptions } from '../../constant';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

const LineSymbolizer = (props) => {
  const { data, handleChangeSymbolizerField } = props;
  const classes = useStyles();

  const [isShow, setShow] = useState(false);
  const [files, setFiles] = useState([]);
  const [filePath, setFilePath] = useState('')
  const [filesTemp, setFilesTemp] = useState([]);
  const [color, setColor] = useState('#ff0000');
  const [fillcolor, setFillColor] = useState('');
  const [strokecolor, setStrokeColor] = useState('');
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [openFillColorPicker, setOpenFillColorPicker] = useState(false);
  const [openStrokeColorPicker, setOpenStrokeColorPicker] = useState(false);

  useEffect(() => {
    if (files && files.length > 0) {
      setFilePath(APIUrlDefault + files[0].filePreview)
    } else { setFilePath('') }
  }, [files])
  const onOpenSelectFile = () => {
    setShow(true);
    setFilesTemp(files);
  };

  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
    console.log('fdjsgfjksdf', filesTemp)
  };

  const onSaveSelectFile = () => {
    console.log(files);
    setShow(false);
  };

  const handleChangeImgPath = (e) => {
    const value = e.target.value;
    setFilePath(value);
  }
  return (
    <div>
      <div className='field_symbolizer_block'>
        <div>
          <div
            className="field_symbolizer_section text-dark font-weight-bold"
            data-toggle="collapse"
            data-target="#multiCollapseGeneral"
            aria-expanded="true"
            aria-controls="multiCollapseGeneral"
          >
            <ArrowDownIcon className='arrow_down' />
            <ArrowRightIcon className='arrow_right' />
            General
          </div>
          <div className="collapse multi-collapse show mt-3" id="multiCollapseGeneral">
            <div className='field_symbolizer'>
              <label className="text-dark">Color</label>
              <div
                className='color_picker_field'
                onClick={() => setOpenColorPicker(true)}
                style={{ background: `${data.color}` }}
              >
                <span>{data.color}</span>
              </div>
              {openColorPicker && (
                <ColorPicker
                  color={data.color}
                  setColor={color => handleChangeSymbolizerField('color', color)}
                  displayColorPicker={openColorPicker}
                  setDisplayColorPicker={setOpenColorPicker}
                />
              )}
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Width</label>
              <input
                type='number'
                className="input_text"
                defaultValue={data.width}
                onChange={e => handleChangeSymbolizerField('width', e.target.value)}
              />
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Perpendicular Offset</label>
              <input
                type='number'
                className="input_text"
                defaultValue={data?.perpendicularOffset}
                onChange={e => handleChangeSymbolizerField('perpendicularOffset', e.target.value)}
              />
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Opacity</label>
              <input
                type='number'
                className="input_text"
                min="0.0"
                max="1.0"
                step="0.1"
                pattern="^0(\.\d+)?|1(\.0+)?$"
                defaultValue={data?.opacity}
                onChange={e => handleChangeSymbolizerField('opacity', e.target.value)}
              />
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Cap</label>
              <Select
                options={capJoinOptions}
                defaultValue={capJoinOptions.filter(x => x.label == data?.cap)}
                onChange={data => handleChangeSymbolizerField('cap', data.label)}
              />
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Join</label>
              <Select
                options={capJoinOptions}
                defaultValue={capJoinOptions.filter(x => x.label == data?.join)}
                onChange={data => handleChangeSymbolizerField('join', data.label)} />
            </div>
          </div>
        </div>
        {/* <hr />
        <div>
          <div
            className="field_symbolizer_section collapsed text-dark font-weight-bold"
            data-toggle="collapse"
            data-target="#multiCollapseStroke"
            aria-expanded="false"
            aria-controls="multiCollapseStroke"
          >
            <ArrowDownIcon className='arrow_down' />
            <ArrowRightIcon className='arrow_right' />
            Graphic
          </div>
          <div className="collapse multi-collapse mt-3" id="multiCollapseStroke">
            <div className='field_symbolizer'>
              <label className="text-dark">Radius</label>
              <input
                type='number'
                className="input_text"
                defaultValue={data.width}
                onChange={e => handleChangeSymbolizerField()}
              />
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Offset X</label>
              <input
                type='number'
                className="input_text"
                defaultValue={data.width}
                onChange={e => handleChangeSymbolizerField()}
              />
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Offset Y</label>
              <input
                type='number'
                className="input_text"
                defaultValue={data.width}
                onChange={e => handleChangeSymbolizerField()}
              />
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Fill-Color</label>
              <div
                className='color_picker_field'
                onClick={() => setOpenFillColorPicker(true)}
                style={{ background: `${fillcolor}` }}
                onChange={e => handleChangeSymbolizerField()}
              >
                <span>{fillcolor ? fillcolor : `Pick color`}</span>
              </div>
              {openFillColorPicker && (
                <ColorPicker
                  color={fillcolor}
                  setColor={setFillColor}
                  displayColorPicker={openFillColorPicker}
                  setDisplayColorPicker={setOpenFillColorPicker}
                  onChange={e => handleChangeSymbolizerField()}
                />
              )}
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Stroke-Color</label>
              <div
                className='color_picker_field'
                onClick={() => setOpenStrokeColorPicker(true)}
                style={{ background: `${strokecolor}` }}
                onChange={e => handleChangeSymbolizerField()}
              >
                <span>{strokecolor ? strokecolor : `Pick color`}</span>
              </div>
              {openStrokeColorPicker && (
                <ColorPicker
                  color={strokecolor}
                  setColor={setStrokeColor}
                  displayColorPicker={openStrokeColorPicker}
                  setDisplayColorPicker={setOpenStrokeColorPicker}
                />
              )}
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Fill-Opacity</label>
              <input
                type='number'
                className="input_text"
                min="0.0"
                max="1.0"
                step="0.1"
                defaultValue={data.width}
                onChange={e => handleChangeSymbolizerField()}
              />
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Rotation</label>
              <input
                type='number'
                className="input_text"
                defaultValue={data.width}
                onChange={e => handleChangeSymbolizerField()}
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default LineSymbolizer;
