import apiConfig, * as ApiConfig from "../../api/api-config";

export const listHeader = [
    {
        id: 'drag',
        title: '',
    },
    // {
    //     id: 'visiable',
    //     title: 'Hiển thị',
    // },
    {
        id: 'symbolizers',
        title: 'Symbolizers',
    },
    {
        id: 'name',
        title: 'Name',
    },
    {
        id: 'filter',
        title: 'Filter',
    },
    {
        id: 'minScale',
        title: 'Min. Scale',
    },
    {
        id: 'maxScale',
        title: 'max. Scale',
    },
    {
        id: 'action',
        title: '',
    },
]

export const listPropertiesFilterOptions = [
    {
        value: 1,
        label: '=='
    },
    {
        value: 2,
        label: '*='
    },
    {
        value: 3,
        label: '!='
    },
    {
        value: 4,
        label: '<'
    },
    {
        value: 5,
        label: '<='
    },
    {
        value: 6,
        label: '>'
    },
    {
        value: 7,
        label: '>='
    },
    {
        value: 8,
        label: '<=x<='
    },
]
export const listSymbolizer = [
    {
        id: 1,
        value: 'Line',
        label: 'Line',
    },
    {
        id: 2,
        value: 'Fill',
        label: 'Polygon (Fill)',
    },
    {
        id: 3,
        value: 'Icon',
        label: 'Point (Icon)',
    },
    {
        id: 4,
        value: 'Mark',
        label: 'Point (Mark)',
    },
]

export const listSymbol = [
    {
        id: 1,
        value: 1,
        label: 'Circle',
    },
    {
        id: 2,
        value: 2,
        label: 'Square',
    },
    {
        id: 3,
        value: 3,
        label: 'Triangle',
    },
    {
        id: 4,
        value: 4,
        label: 'Star',
    },
    {
        id: 6,
        value: 6,
        label: 'Cross',
    },
]

export const listRuleDemo = [
    {
        id: 1,
        name: 'Rule 1',
        index: 1,
        type: 2,
        minScale: 0,
        maxScale: 1,
        isCheck: true
    },
    {
        id: 2,
        name: 'Rule 2',
        index: 2,
        type: 1,
        minScale: 0,
        maxScale: 1,
        isCheck: true
    },
    {
        id: 1,
        name: 'Rule 3',
        index: 3,
        type: 4,
        minScale: 0,
        maxScale: 1,
        isCheck: true
    },

]

export const capJoinOptions = [
    {
        value: 1,
        label: 'butt'
    },
    {
        value: 2,
        label: 'round'
    },
    {
        value: 3,
        label: 'square'
    },
    {
        value: 4,
        label: 'bevel'
    }
]

const getRandomHexColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const lineSymbolizerDefault = {
    kind: 'Line',
    width: 5,
    color: getRandomHexColor()
}

export const fillSymbolizerDefault = {
    kind: 'Fill',
    color: getRandomHexColor()
}

export const IconSymbolizerDefault = {
    kind: 'Icon',
    image: `https://openlayers.org/en/latest/examples/data/icon.png`,
    size: 64,
}

export const markSymbolizerDefault = {
    kind: 'Mark',
    color: getRandomHexColor(),
    wellKnownName: 'circle'
}