import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    makeStyles,
    Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
    listSymbolizer,
    lineSymbolizerDefault,
    fillSymbolizerDefault,
    IconSymbolizerDefault,
    markSymbolizerDefault,
} from '../constant';
import PointSymbolizer from './render-field/point-symbolizer';
import PointMarkSymbolizer from './render-field/point-mark-symbolizer';
import PolygonSymbolizer from './render-field/polygon-symbolizer';
import LineSymbolizer from './render-field/line-symbolizer';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(1),
    },
}));

const Symbolizer = (props) => {
    const { isOpen, onClose, data, index, handleChangeRuleSymbolizer } = props;
    const classes = useStyles();
    const [kindSelected, setKindSelected] = useState({
        id: 2,
        value: 'Line',
        label: 'Line',
    },)

    useEffect(() => {
        if (data) {
            // console.log('data', data)
            setKindSelected(listSymbolizer.filter(x => x.value == data.kind)[0])
        }

    }, [data])

    const handleChangeKind = (data) => {
        setKindSelected(data);
        console.log('data', data)
        switch (data.value) {
            case 'Line':
                handleChangeRuleSymbolizer(lineSymbolizerDefault, index);
                break;
            case 'Fill':
                handleChangeRuleSymbolizer(fillSymbolizerDefault, index);
                break;
            case 'Icon':
                handleChangeRuleSymbolizer(IconSymbolizerDefault, index);
                break;
            case 'Mark':
                handleChangeRuleSymbolizer(markSymbolizerDefault, index);
                break;
            default:
                return;
        }
        handleChangeRuleSymbolizer({

        })
    }
    const handleChangeSymbolizerField = (key, value) => {
        handleChangeRuleSymbolizer({ ...data, [key]: value }, index)
    }

    const handleChangeSymbolizerGraphicField = (key, value) => {
        handleChangeRuleSymbolizer(
            {
                ...data,
                graphicFill: {
                    ...data?.graphicFill,
                    [key]: value
                }
            }
            , index)
    }

    const renderSymbolizerField = type => {
        switch (type) {
            case 'Line': return (
                <LineSymbolizer
                    data={data}
                    handleChangeSymbolizerField={handleChangeSymbolizerField}
                />
            )
            case 'Fill': return (
                <PolygonSymbolizer
                    data={data}
                    handleChangeSymbolizerField={handleChangeSymbolizerField}
                    handleChangeSymbolizerGraphicField={handleChangeSymbolizerGraphicField}
                />
            )
            case 'Icon': return (
                <PointSymbolizer
                    data={data}
                    handleChangeSymbolizerField={handleChangeSymbolizerField}
                />
            )
            case 'Mark': return (
                <PointMarkSymbolizer
                    data={data}
                    handleChangeSymbolizerField={handleChangeSymbolizerField}
                />
            )
            default: return (
                <LineSymbolizer
                    data={data}
                    handleChangeSymbolizerField={handleChangeSymbolizerField}
                />
            )
        }
    }

    return (
        <div>
            <Dialog className="h-100" open={isOpen} onClose={onClose} maxWidth="md">
                <DialogTitle disableTypography className="border-bottom">
                    <Typography variant="h6">Symbolizer Editor</Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="symbolizer_wraper">
                    <div>
                        <label className="text-dark font-weight-bold">Kind</label>
                        <Select
                            options={listSymbolizer}
                            value={kindSelected}
                            onChange={handleChangeKind}
                        />
                    </div>
                    {renderSymbolizerField(kindSelected.value)}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Symbolizer
