import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mapStore from '../../redux/store/map-openlayer/map-openlayer.store';
import * as config from '../../utils/configuration';
import { fromLonLat } from 'ol/proj';

import "./map.scss";
class MapOpenlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mapLib: {},
            map: null,
            mapContainerStyle: null,
            currentLayer: null,
            defaultLayers: [],
            currentLayerSettingModel: null,
            useFeatureViewInfomationOnClick: false,
            modals: false,
            coordinate: this.props.coordinate,
            listLayerSettingModel: [],
            primaryIdLayer: 0,
            isLoadedAllVectorSource: false,
            VectorSourceTest: this.props.mapLib.VectorSource,
            isOpenPrintBox: false,
            mapName: props.mapName,
            isOpenShowModalEstate: false,
            currentEstate: '',
            isOpen: false,
            data: null,
            isProjectOpen: false,
            projectData: null,

        };
    }

    componentDidMount() {
        const Map = this.props.mapLib.Map;
        const View = this.props.mapLib.View;
        const Overlay = this.props.mapLib.Overlay;
        const XYZ = this.props.mapLib.XYZ;
        const TileWMS = this.props.mapLib.TileWMS;
        const TileLayer = this.props.mapLib.TileLayer;
        const defaultControls = this.props.mapLib.defaultControls;
        const MousePosition = this.props.mapLib.MousePosition;
        const Rotate = this.props.mapLib.Rotate;
        const VectorLayer = this.props.mapLib.VectorLayer;
        const VectorSource = this.props.mapLib.VectorSource;

        this.setState({
            mapLib: this.props.mapLib
        });

        /*******************************************************************/

        const defaultBaseMapUrl = config.googleTrafficUrl;

        const defaultZoom = this.getDefaultZoom();
        const defaultMaxZoom = this.props.maxZoom || 24;
        const defaultMinZoom = this.props.minZoom || 1;
        const defaultListLayer = this.props.listLayer || [];
        const defaultProjection = this.props.projection || 'EPSG:4326';
        const defaultMapCenter = this.getDefaultMapCenter();

        const defaultExtend = this.props.extent || [
            config.defaultLeftCornerLng,
            config.defaultLeftCornerLat,
            config.defaultRightCornerLng,
            config.defaultRightCornerLat
        ];

        const baseMap = new TileLayer({
            source: new XYZ({
                url: defaultBaseMapUrl,
                maxZoom: defaultMaxZoom,
                crossOrigin: 'anonymous'
            })
        });

        const customVectorLayer = new VectorLayer({
            zIndex: 9999,
            className: 'custom-vector-layer',
            source: new VectorSource({
                features: []
            }),
        });

        let defaultLayers = this.state.defaultLayers;
        defaultLayers.push(baseMap);
        defaultLayers.push(customVectorLayer);

        this.setState({
            listLayerSettingModel: defaultListLayer,
            isLoadedAllVectorSource: !defaultListLayer.length
        });

        defaultListLayer.map((layerData, index) => {
            const layerImage = new TileLayer({
                visible: layerData.is_check,
                zIndex: layerData.z_index,
                minZoom: layerData.min_zoom,
                maxZoom: layerData.max_zoom,
                source: new TileWMS({
                    url: layerData.wms,
                    params: {
                        LAYERS: `qhdongtrieu:${layerData.table}`,
                        LayerId: layerData.id,
                        FORMAT: 'image/png',
                        VERSION: '1.1.0'
                    },
                    crossOrigin: 'anonymous'
                }),
                // className: TileLayerClassName(layerData.id)
            });

            defaultLayers.push(layerImage);
            // DefaultLayers.push(vectorLayer);
            if (index === 0) {
                this.setState({
                    currentLayer: layerImage,
                    currentLayerSettingModel: layerData,
                    primaryIdLayer: layerData.id
                });
            }
        });

        this.setState({
            defaultLayers: defaultLayers
        });
        const IdIntervalSettingMap = setInterval(() => {
            if (defaultLayers && !this.state.map) {
                const mousePositionControl = new MousePosition({
                    coordinateFormat: this.props.mapLib.createStringXY(4),
                    projection: 'EPSG:4326',
                    className: 'custom-mouse-position',
                    target: document.getElementById('mouse-position'),
                    undefinedHTML: '&nbsp;'
                });

                const rotateControl = new Rotate({
                    autoHide: false
                });

                const baseMapObject = new Map({
                    controls: defaultControls.extend([mousePositionControl, rotateControl]),
                    target: 'map-container',
                    layers: defaultLayers,
                    view: new View({
                        projection: defaultProjection,
                        zoom: defaultZoom,
                        minZoom: defaultMinZoom,
                        maxZoom: defaultMaxZoom,
                        // extent: defaultExtend,
                        center: defaultMapCenter
                    })
                });

                baseMapObject.addOverlay(
                    new Overlay({
                        position: [0, 0],
                        element: document.getElementById('null')
                    })
                );

                this.setState({
                    map: baseMapObject,
                    mapLib: this.props.mapLib
                });
                this.props.CreateBaseMap(baseMapObject);
                clearInterval(IdIntervalSettingMap);
            }
        }, 500);
        const openlayerHandleFunctionList = {
            setMapCenter: this.setMapCenter,
            setMapZoom: this.setMapZoom,
            unpinFeature: this.unpinFeature,
            displayLineFeature: this.displayLineFeature,
            displayIconFeature: this.displayIconFeature,
            displayFillFeature: this.displayFillFeature,
            displayMarkFeature: this.displayMarkFeature,
        };
        this.setHeightViewport();
        this.props.CreateOpenlayerController(openlayerHandleFunctionList);
    }

    setHeightViewport = () => {
        if (!this.state.map) return
        const HeaderContainerHeight = 400;
        // const HeaderContainerHeight = (document.getElementById(
        //     "header-map-page-container"
        //   )).offsetHeight;  
        this.setState(
            {
                mapContainerStyle: {
                    height: `${window.innerHeight - HeaderContainerHeight}px`,
                },
            },
            () => {
                this.state.map.updateSize();
            }
        );
    };

    handlePinMarkerMode = isTurnOn => {
        const VectorLayer = this.props.mapLib.VectorLayer;
        const VectorSource = this.props.mapLib.VectorSource;
        const Style = this.props.mapLib.Style;
        const Icon = this.props.mapLib.Icon;

        const LayersCurrents = this.state.map?.getLayers();
        const openlayerMap = this.state?.map;
        const anchorXUnits = 'fraction';
        const anchorYUnits = 'pixels';
        if (isTurnOn) {
            const PinMarkerLayer = new VectorLayer({
                zIndex: 9999,
                className: 'pin-marker-vector-layer',
                source: new VectorSource({
                    features: []
                }),
                style: new Style({
                    image: new Icon({
                        anchor: [0.5, 22],
                        anchorXUnits: anchorXUnits,
                        anchorYUnits: anchorYUnits,
                        src: 'assets/icon/pin-home60x60.png'
                    })
                })
            });
            LayersCurrents.push(PinMarkerLayer);
            openlayerMap.on('singleclick', this.functionClickPinMarker_PinMarkerMode);
        } else {
            let layerIndex = -1;
            LayersCurrents.forEach((layerObject, index) => {
                if (layerObject.getClassName() === 'pin-marker-vector-layer') {
                    layerIndex = index;
                    return;
                }
            });
            if (layerIndex != -1) LayersCurrents.removeAt(layerIndex);
            openlayerMap.un('singleclick', this.functionClickPinMarker_PinMarkerMode);
        }
    };

    functionClickPinMarker_PinMarkerMode = event => {
        console.log('event.coordinate', event.coordinate)
        this.props.setCoordinate(event.coordinate);
        const Feature = this.props.mapLib.Feature;
        const Point = this.props.mapLib.Point;
        const Style = this.props.mapLib.Style;
        const Icon = this.props.mapLib.Icon;
        const LayersCurrents = this.state.map?.getLayers();
        LayersCurrents.forEach(layerObject => {
            if (layerObject.getClassName() === 'pin-marker-vector-layer') {
                const newMarker = new Feature({
                    type: 'icon',
                    geometry: new Point(event.coordinate)
                });
                let markerSrc = '/assets/icon/red-marker.png';

                newMarker.setStyle(
                    new Style({
                        image: new Icon({
                            scale: 1,
                            anchor: [0.5, 28],
                            anchorXUnits: 'fraction',
                            anchorYUnits: 'pixels',
                            opacity: 1,
                            src: markerSrc
                        }),
                    })
                );
                this.unpinFeature('pin-marker-vector-layer');

                layerObject.getSource().addFeature(newMarker);
                return;
            }
        });
    };

    unpinFeature = className => {
        const LayersCurrents = this.state.map?.getLayers();
        LayersCurrents.forEach(layerObject => {
            if (layerObject.getClassName() === className) {
                // Clear all features in the layer
                layerObject.getSource().clear();
                return;
            }
        });
    }

    unpinObject = pinClass => {
        const VectorLayer = this.props.mapLib.VectorLayer;
        const _objectMap = this.state.priceMap;
        if (!_objectMap) return;
        const _layers = _objectMap?.getLayers();

        _layers.getArray().map((_layer, _index) => {
            console.log({ _layer, className: _layer.getClassName() });
            if (_layer instanceof VectorLayer && _layer.getClassName() == `${pinClass}`) {
                _layers.removeAt(_index);
            }
        });
    };

    getDefaultMapCenter = () => {
        let defaultCenter;
        // console.log('this.props.defaultCenter__', this.props.defaultCenter);
        if (
            this.props.defaultCenter &&
            Array.isArray(this.props.defaultCenter) &&
            parseFloat(this.props.defaultCenter[0]) > 0 &&
            parseFloat(this.props.defaultCenter[1]) > 0
        ) {
            // console.log('defaultCenter111111111111');
            defaultCenter = this.props.defaultCenter;
        } else {
            // console.log('defaultCenter22222222222222');
            defaultCenter = config.defaultMapCenter;
        }
        return defaultCenter;
    };

    getDefaultZoom = () => {
        let defaultZoom = config.defaultZoom;
        if (this.props.mapZoom) {
            defaultZoom = this.props.mapZoom;
        }
        return defaultZoom;
    };

    setMapCenter = (coordinate) => {
        console.log('fdhgkjsdfsd', coordinate)
        this.state.map.getView().setCenter(coordinate);
    };

    setMapZoom = zoom => {
        if (zoom > 0) {
            this.state.map.getView().setZoom(zoom);
        }
    };

    displayLineFeature = (geometryString, style) => {
        console.log('style', style)
        console.log('geometryString', geometryString)
        const VectorLayer = this.props.mapLib.VectorLayer;
        const VectorSource = this.props.mapLib.VectorSource;
        const LineString = this.props.mapLib.LineString;
        const Style = this.props.mapLib.Style;
        const Feature = this.props.mapLib.Feature;
        const Stroke = this.props.mapLib.Stroke;
        const LayersCurrents = this.state.map?.getLayers();
        console.log('đã chạy', LayersCurrents)
        LayersCurrents.forEach(layerObject => {
            if (layerObject.getClassName() === 'custom-vector-layer') {
                const lineFeature = new Feature({
                    geometry: new LineString(geometryString),
                });
                // Tạo style cho đường
                lineFeature.setStyle(style);
                layerObject.getSource().addFeature(lineFeature);
                return
            }
        })

    }

    displayIconFeature = (coordinate, style) => {
        const Feature = this.props.mapLib.Feature;
        const Point = this.props.mapLib.Point;
        const Style = this.props.mapLib.Style;
        const Icon = this.props.mapLib.Icon;
        const LayersCurrents = this.state.map?.getLayers();
        console.log('ok', LayersCurrents)
        LayersCurrents.forEach(layerObject => {
            if (layerObject.getClassName() === 'custom-vector-layer') {
                const newMarker = new Feature({
                    type: 'icon',
                    geometry: new Point(coordinate)
                });

                newMarker.setStyle(style);

                layerObject.getSource().addFeature(newMarker);
                return;
            }
        });
    };

    displayMarkFeature = (coordinate, style) => {
        const Feature = this.props.mapLib.Feature;
        const Point = this.props.mapLib.Point;
        const Style = this.props.mapLib.Style;
        const Icon = this.props.mapLib.Icon;
        const LayersCurrents = this.state.map?.getLayers();
        console.log('ok', LayersCurrents)
        LayersCurrents.forEach(layerObject => {
            if (layerObject.getClassName() === 'custom-vector-layer') {
                const newMarker = new Feature({
                    type: 'icon',
                    geometry: new Point([108.020738, 13.994620])
                });
                let markerSrc = 'https://openlayers.org/en/latest/examples/data/icon.png';

                newMarker.setStyle(
                    new Style({
                        image: new Icon({
                            scale: 1,
                            anchor: [0.5, 28],
                            anchorXUnits: 'fraction',
                            anchorYUnits: 'pixels',
                            opacity: 1,
                            src: markerSrc
                        }),
                    })
                );

                layerObject.getSource().addFeature(newMarker);
                return;
            }
        });
    };

    displayFillFeature = (geometryString, style) => {
        const Feature = this.props.mapLib.Feature;
        const Style = this.props.mapLib.Style;
        const Polygon = this.props.mapLib.Polygon;
        const Fill = this.props.mapLib.Fill;
        const Stroke = this.props.mapLib.Stroke;
        const LineString = this.props.mapLib.LineString;

        const LayersCurrents = this.state.map?.getLayers();
        console.log('ok', LayersCurrents)
        LayersCurrents.forEach(layerObject => {
            if (layerObject.getClassName() === 'custom-vector-layer') {
                const polygonFeature = new Feature({
                    geometry: new Polygon([geometryString])
                });

                // const img = new Image();
                // img.src = 'https://openlayers.org/en/latest/examples/data/icon.png'; // Đường dẫn hình ảnh
                // img.onload = () => {
                //     const pattern = document.createElement('canvas').getContext('2d').createPattern(img, 'repeat');

                //     // Tạo style cho polygon với fill là hình ảnh
                //     const polygonStyle = new Style({
                //         fill: new Fill({
                //             color: pattern, // Sử dụng CanvasPattern làm fill
                //         }),
                //         stroke: new Stroke({
                //             color: '#00FF00', // Màu viền
                //             width: 2, // Độ dày viền
                //         }),
                //     });
                //     polygonFeature.setStyle(polygonStyle);
                //     // Áp dụng style cho polygon
                //     // polygonFeature.setStyle(style);
                //     layerObject.getSource().addFeature(polygonFeature);
                //     return
    
                // }
                // Áp dụng style cho polygon
                polygonFeature.setStyle(style);
                layerObject.getSource().addFeature(polygonFeature);
                return
            }
        });
    };

    render() {
        return (
            <main className="page-content">
                <div className="contact__map position-relative" style={{ overflow: 'hidden' }}>
                    <div id="map-container" style={this.state.mapContainerStyle}></div>
                </div>
            </main>
        );
    }

}

const mapStateToProps = state => ({
    baseMap: state.mapOpenlayer.baseMap,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            CreateOpenlayerController: mapStore.CreateOpenlayerController,
            CreateBaseMap: mapStore.CreateBaseMap,
            SaveOpenlayerFeaturesSelected: mapStore.SaveOpenlayerFeaturesSelected,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapOpenlayer);
