import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../core/app.store";
import * as gsActions from "../../redux/store/geo-style/geo-style.store";

import { sldToOl, olToSld, sldToGS, gStoSld, handleFormatXml, geoStylerToOlStyle } from '../graphical-editor/components/function-handle/function-handle';
import { styleSld, styleOpenLayer, iconCoordinateDefault, lineStringDefault, polygonStringDefault } from './constant';
import { UnControlled as CodeMirror } from 'react-codemirror2'
import SLDParser from 'geostyler-sld-parser';
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/xml/xml';
import './style-editor.scss';
import { geoStylerDeno } from './constant';

const sldParser = new SLDParser();

function StyleEditor(props) {
    const { geoStyler } = props;
    const [sldValue, setSldValue] = useState(null);
    const [tempSldValue, setTempSldValue] = useState(null);
    // const [sldValue, setSldValue] = useState(styleOpenLayer);
    // const [tempSldValue, setTempSldValue] = useState(styleOpenLayer);

    const [style, setStyle] = useState(null);
    const [olValue, setOlValue] = useState(null);

    useEffect(() => {
        handleConvertSldToGS(sldValue);
    }, [])

    useEffect(() => {
        if (geoStyler) console.log('geoStyler', geoStyler)
        handleConvertGsToSld(geoStyler)
    }, [geoStyler])

    useEffect(() => {
        if (geoStyler.rules.length > 0 && props.openLayerController && props.baseMap) {
            // console.log('geoStylerToOlStyle', geoStyler.rules)
            console.log('geoStylerToOlStyle', geoStylerToOlStyle(geoStyler))
            const geoStylerOl = geoStylerToOlStyle(geoStyler);
            props.openLayerController.unpinFeature('custom-vector-layer')
            geoStylerOl.listStyle.map((style) => {
                switch (style.kind) {
                    case 'Icon':
                        props.openLayerController.displayIconFeature(iconCoordinateDefault, style.style)
                        break;
                    case 'Mark':
                        props.openLayerController.displayMarkFeature(iconCoordinateDefault, style.style)
                        break;
                    case 'Line':
                        props.openLayerController.displayLineFeature(lineStringDefault, style.style)
                        break;
                    case 'Fill':
                        props.openLayerController.displayFillFeature(polygonStringDefault, style.style)
                        break;
                    default:
                        return
                }
            })

        }
    }, [geoStyler.rules, props.openLayerController, props.baseMap])

    const handleStyleChange = (newStyle) => {
        setStyle(newStyle);
        sldParser.writeStyle(newStyle).then(sld => {
            setSldValue(sld); // Cập nhật mã SLD trong CodeMirror
            // const wmsLayer = map.getLayers().item(1);
            // wmsLayer.getSource().updateParams({ STYLES: sld });
        });
    };

    const handleCodeChange = (editor, data, value) => {
        setTempSldValue(value)
        // sldParser.readStyle(value)
        //     .then(newStyle => handleStyleChange(newStyle))
        //     .catch(error => console.error('Error parsing updated style:', error));
    };

    const handleRun = () => {
        setSldValue(tempSldValue);
        handleConvertSldToGS(tempSldValue);
        // props.openLayerController && props.openLayerController.displayLineFeature()
    }

    const handleConvertSldToGS = async (sld) => {
        if (!sld) return;
        try {
            const res = await sldToGS(sld);
            if (res) {
                props.setGeoStyler(res)
            }
        }
        catch (e) { }
    }

    const handleConvertGsToSld = async (geoStyle) => {
        if (!geoStyle.rules.length) return;
        try {
            const res = await gStoSld(geoStyle);
            if (res) {
                setSldValue(res)
            }
        }
        catch (e) { }
    }

    return (
        <div>
            <div className="header_">
                <span>{`Style Editor (SLD Style Parser)`}</span>
                <Button
                    className="btn_run"
                    color="primary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => handleRun()}
                >
                    Run
                </Button>
            </div>
            <CodeMirror
                value={sldValue}
                options={{
                    mode: 'xml',
                    theme: 'colorforth',
                    lineNumbers: true,
                }}
                // onBeforeChange={(editor, data, value) => handleCodeChange(editor, data, value)}
                onChange={(editor, data, value) => handleCodeChange(editor, data, value)}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
    geoStyler: state.geoStyler.geoStyler,
    openLayerController: state.mapOpenlayer.openLayerController,
    baseMap: state.mapOpenlayer.baseMap,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
            setGeoStyler: gsActions.setGeoStyler,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(StyleEditor);
