import React, { useEffect, useState } from 'react'
import FileManagement from "../../../file_management/file_management";
import { NotificationMessageType, APIUrlDefault } from "../../../../utils/configuration";

import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  IconButton,
  makeStyles,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import ImgIcon from "@material-ui/icons/Image";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDownIcon from "@material-ui/icons/ArrowDropDown";
import ColorPicker from '../color-picker/color-picker';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

const PolygonSymbolizer = (props) => {
  const { data, handleChangeSymbolizerField, handleChangeSymbolizerGraphicField } = props;
  const classes = useStyles();

  const [isShow, setShow] = useState(false);
  const [files, setFiles] = useState([]);
  const [filePath, setFilePath] = useState('')
  const [filesTemp, setFilesTemp] = useState([]);
  const [color, setColor] = useState('#ff0000');
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [openOutLineColorPicker, setOpenOutLineColorPicker] = useState(false);

  useEffect(() => {
    if (files && files.length > 0) {
      setFilePath(APIUrlDefault + files[0].filePreview)
      handleChangeSymbolizerGraphicField('image', APIUrlDefault + files[0].filePreview)
    } else { setFilePath('') }
  }, [files])

  const onOpenSelectFile = () => {
    setShow(true);
    setFilesTemp(files);
  };

  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
    console.log('fdjsgfjksdf', filesTemp)
  };

  const onSaveSelectFile = () => {
    console.log(files);
    setShow(false);
  };

  const handleChangeImgPath = (e) => {
    const value = e.target.value;
    setFilePath(value);
  }
  return (
    <div>
      <div className='field_symbolizer_block'>
        <div>
          <div
            className="field_symbolizer_section text-dark font-weight-bold"
            data-toggle="collapse"
            data-target="#multiCollapseGeneral"
            aria-expanded="true"
            aria-controls="multiCollapseGeneral"
          >
            <ArrowDownIcon className='arrow_down' />
            <ArrowRightIcon className='arrow_right' />
            General
          </div>
          <div className="collapse multi-collapse show mt-3" id="multiCollapseGeneral">
            <div className='field_symbolizer'>
              <label className="text-dark">Fill-Color</label>
              <div
                className='color_picker_field'
                onClick={() => setOpenColorPicker(true)}
                style={{ background: `${data.color}` }}
              >
                <span>{data.color}</span>
              </div>
              {openColorPicker && (
                <ColorPicker
                  color={data.color}
                  setColor={color => handleChangeSymbolizerField('color', color)}
                  displayColorPicker={openColorPicker}
                  setDisplayColorPicker={setOpenColorPicker}
                />
              )}
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Fill-Opacity</label>
              <input
                type='number'
                className="input_text"
                min="0.0"
                max="1.0"
                step="0.1"
                pattern="^0(\.\d+)?|1(\.0+)?$"
                defaultValue={data?.fillOpacity}
                onChange={e => handleChangeSymbolizerField('fillOpacity', e.target.value)}
              />
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Outline-Color</label>
              <div
                className='color_picker_field'
                onClick={() => setOpenOutLineColorPicker(true)}
                style={{ background: `${data?.outlineColor}` }}
              >
                <span>{data?.outlineColor ?? 'Pick color'}</span>
              </div>
              {openOutLineColorPicker && (
                <ColorPicker
                  color={data?.outlineColor}
                  setColor={color => handleChangeSymbolizerField('outlineColor', color)}
                  displayColorPicker={openOutLineColorPicker}
                  setDisplayColorPicker={setOpenOutLineColorPicker}
                />
              )}
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Outline-Opacity</label>
              <input
                type='number'
                className="input_text"
                min="0.0"
                max="1.0"
                step="0.1"
                pattern="^0(\.\d+)?|1(\.0+)?$"
                defaultValue={data?.outlineOpacity}
                onChange={e => handleChangeSymbolizerField('outlineOpacity', e.target.value)}
              />
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Outline-Width</label>
              <input
                type='number'
                className="input_text"
                defaultValue={data?.outlineWidth}
                onChange={e => handleChangeSymbolizerField('outlineWidth', e.target.value)}
              />
            </div>
            {/* <div className='field_symbolizer'>
              <label className="text-dark">Outline-Dasharray</label>
              <input
                type='number'
                className="input_text"
              />
            </div> */}
          </div>
        </div>
        <hr />
        <div>
          <div
            className="field_symbolizer_section collapsed text-dark font-weight-bold"
            data-toggle="collapse"
            data-target="#multiCollapseFill"
            aria-expanded="false"
            aria-controls="multiCollapseFill"
          >
            <ArrowDownIcon className='arrow_down' />
            <ArrowRightIcon className='arrow_right' />
            Graphic Fill
          </div>
          <div className="collapse multi-collapse mt-3" id="multiCollapseFill">
            <div className='field_symbolizer'>
              <label className="text-dark">Source Image</label>
              {data?.graphicFill?.image && (
                <img
                  src={data?.graphicFill?.image}
                  alt='Kiểm tra lại dường dẫn ảnh'
                  className="img-fluid mb-2"
                  style={{
                    width: "150px",
                    height: "auto",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              )}
              <div className='upload_img'>
                <input
                  type='text'
                  className="input_text"
                  value={data?.graphicFill?.image}
                  onChange={e => handleChangeSymbolizerGraphicField('image', e.target.value)}
                />
                <div className='btn_upload_img'>
                  <Tooltip title="Ảnh từ hệ thống">
                    <ImgIcon onClick={onOpenSelectFile} />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Size</label>
              <input
                type='number'
                className="input_text"
                defaultValue={data?.graphicFill?.size}
                onChange={e => handleChangeSymbolizerGraphicField('size', e.target.value)}
              />
            </div>
            {/* <div className='field_symbolizer'>
              <label className="text-dark">Offset X</label>
              <input
                type='number'
                className="input_text"
                defaultValue={data?.graphicFill?.size}
                onChange={e => handleChangeSymbolizerGraphicField('size', e.target.value)}
              />
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Offset Y</label>
              <input
                type='number'
                className="input_text"
                defaultValue={data?.graphicFill?.size}
                onChange={e => handleChangeSymbolizerGraphicField('image', e.target.value)}
              />
            </div> */}
            <div className='field_symbolizer'>
              <label className="text-dark">Rotation</label>
              <input
                type='number'
                className="input_text"
                defaultValue={data?.graphicFill?.rotate}
                onChange={e => handleChangeSymbolizerGraphicField('rotate', parseFloat(e.target.value))}
              />
            </div>
            <div className='field_symbolizer'>
              <label className="text-dark">Opacity</label>
              <input
                type='number'
                className="input_text"
                min="0.0"
                max="1.0"
                step="0.1"
                defaultValue={data?.graphicFill?.opacity}
                onChange={e => handleChangeSymbolizerGraphicField('opacity', e.target.value)}
              />
            </div>
            {isShow && (
              <Dialog
                onClose={onCloseSelectFile}
                open={isShow}
                fullWidth={true}
                maxWidth="md"
                className="dialog-preview-form"
              >
                <DialogTitle disableTypography>
                  <Typography variant="h6">Quản lý file</Typography>
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onCloseSelectFile}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                  <FileManagement
                    files={files}
                    setFiles={setFiles}
                    acceptedFiles={["jpeg", "png", "jpg", "gif"]}
                  />
                </DialogContent>

                <DialogActions>
                  <Button
                    type="button"
                    onClick={onCloseSelectFile}
                    variant="contained"
                    startIcon={<CloseIcon />}
                  >
                    Hủy
                  </Button>
                  {files && files.length > 0 && (
                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      startIcon={<SaveIcon />}
                      onClick={onSaveSelectFile}
                    >
                      Lưu
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PolygonSymbolizer
